export const UPLOAD_INVOICE_REFETCH_TIMEOUT = 120000;
export const UPLOAD_SINGLE_REFETCH_TIMEOUT = 30000;
export const SEARCH_DEBOUNCE_TIMEOUT = 600;

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
