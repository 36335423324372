import React from 'react';

import { Skeleton } from '@mui/lab';

const NotificationsSkeleton = () => (
  <>
    {Array.from({ length: 3 }, (_, i) => (
      <Skeleton key={`notifications-${i}`} height={74} sx={{ mb: 2 }} variant="rounded" />
    ))}
  </>
);

export default NotificationsSkeleton;
