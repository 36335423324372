import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { PAGE_HEADER_RAW_HEIGHT } from '@constants/common';
import { useViewInvoice } from '@hooks/api/useViewInvoice';
import { useBreakpoints } from '@hooks/useBreakpoints';
import ActiveTab from '@pages/InvoiceDetails/components/ActiveTab';
import DetailsHeader from '@pages/InvoiceDetails/components/DetailsHeader';
import ToggleTabs from '@pages/InvoiceDetails/components/ToggleTabs';
import {
  ABN_FIELD,
  CATEGORY_FIELD,
  DESCRIPTION_FIELD,
  PARTICIPANT_FIELD,
  SERVICE_END_DATE_FIELD,
  SERVICE_EXACT_DATE_FIELD,
  SERVICE_START_DATE_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetails/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';

import { Box, LinearProgress } from '@mui/material';

type MissingDataFieldKeys =
  | typeof DESCRIPTION_FIELD
  | typeof CATEGORY_FIELD
  | typeof VENDOR_NAME_FIELD
  | typeof PARTICIPANT_FIELD
  | typeof SERVICE_EXACT_DATE_FIELD;

const InvoiceDetails: FC = () => {
  const {
    isLoadingInvoices,
    invoiceData,
    isLoadingCategoryOptions,
    handleResetForm,
    isLoadingReasons,
    invoiceForm,
    isLoadingChanges,
    isLoadingUser,
    totalIssues,
    setTotalIssues,
  } = useInvoiceDetailsNewContext();
  const { t } = useTranslation();
  const { downMd, upLg } = useBreakpoints();
  const { errors: invoiceFormErrors } = invoiceForm.formState;
  useViewInvoice({ id: invoiceData?.id, skip: invoiceData?.is_viewed });

  const isLoading = [
    isLoadingInvoices,
    isLoadingCategoryOptions,
    isLoadingReasons,
    isLoadingChanges,
    isLoadingUser,
  ].some(Boolean);

  useEffect(() => {
    const filteredFormErrors = Object.keys(invoiceFormErrors).filter(
      val => ![SERVICE_EXACT_DATE_FIELD, SERVICE_START_DATE_FIELD, SERVICE_END_DATE_FIELD, ABN_FIELD].includes(val),
    );

    const formErrorFieldsSet = new Set(filteredFormErrors);

    const missingDataFieldMap: Record<MissingDataFieldKeys, boolean> = {
      [DESCRIPTION_FIELD]: !!invoiceData?.description,
      [CATEGORY_FIELD]: !!invoiceData?.category?.name,
      [VENDOR_NAME_FIELD]: !!invoiceData?.vendor_name,
      [PARTICIPANT_FIELD]: !!invoiceData?.participant,
      [SERVICE_EXACT_DATE_FIELD]:
        !!invoiceData?.service_exact_date || (!!invoiceData?.service_start_date && !!invoiceData?.service_end_date),
    };

    const missingDataIssues = (Object.keys(missingDataFieldMap) as MissingDataFieldKeys[]).filter(
      field => !missingDataFieldMap[field] && !formErrorFieldsSet.has(field),
    ).length;

    const totalCount = filteredFormErrors.length + missingDataIssues;

    setTotalIssues(totalCount);
  }, [
    invoiceData?.description,
    invoiceData?.category?.name,
    invoiceData?.vendor_name,
    invoiceData?.service_exact_date,
    invoiceData?.service_start_date,
    invoiceData?.service_end_date,
    invoiceData?.abn,
    invoiceForm.formState.errors,
    invoiceData?.participant,
    invoiceFormErrors,
    setTotalIssues,
  ]);

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
    invoiceForm.trigger();
  }, [handleResetForm, invoiceData, invoiceForm]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoiceDetails')}</title>
      </Helmet>

      <DetailsHeader data={invoiceData} actionRequired={!!totalIssues} />

      <Box height={`calc(100% - ${PAGE_HEADER_RAW_HEIGHT}px)`} width="auto">
        <ToggleTabs />
        <Box
          px={upLg ? '80px' : downMd ? '20px' : '30px'}
          pt={downMd ? '21px' : '40px'}
          pb={downMd ? '38px' : '60px'}
          width="100%"
        >
          <ActiveTab />
        </Box>
      </Box>
    </>
  );
};

export default memo(InvoiceDetails);
