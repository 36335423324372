import {
  ChangeEvent,
  createContext,
  FC,
  MouseEvent,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  InvoiceEventTypeEnum,
  useApiInvoicesEventsCreateMutation,
  useApiInvoicesUpdatePartialUpdateMutation,
} from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInvoicesData } from '@hooks/api/useInvoicesData';
import { useTabs } from '@hooks/useTabs';
import { DocumentView, SortBy, TaskView } from '@pages/Tasks/enums';
import { editClaimSchema, editPaySchema } from '@pages/Tasks/schema';
import { compareDates, compareVendorNames, getTotalSelectedCost, transformInvoices } from '@pages/Tasks/utils';
import { getErrorMessage } from '@utils/getMessage';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { EditClaim, EditClaimSchema, EditPay, EditPaySchema } from 'src/pages/Tasks/types';

const TasksContext = createContext<ReturnType<typeof useTasksContextValue> | null>(null);

const useTasksContextValue = () => {
  const snackbar = useSnackbar();

  const { isLoadingInvoices, unclaimedInvoices, unpaidInvoices } = useInvoicesData({ orderBy: 'created_at' });

  const [invoicePartialUpdateMutation, { isLoading: isLoadingPartialUpdate }] =
    useApiInvoicesUpdatePartialUpdateMutation();
  const [createEventMutation] = useApiInvoicesEventsCreateMutation();

  const { tab, handleChange } = useTabs(TaskView.CLAIM, TaskView);
  const [claimSelectedIds, setClaimSelectedIds] = useState<[] | number[]>([]);
  const [paySelectedIds, setPaySelectedIds] = useState<[] | number[]>([]);
  const [documentView, setDocumentView] = useState<DocumentView>(DocumentView.DETAILS);
  const [isLoadingMarkInvoices, setIsLoadingMarkInvoices] = useState(false);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.NONE);
  const [showOnlyClaimed, setShowOnlyClaimed] = useState(false);

  const isClaimView = tab === TaskView.CLAIM;
  const isPayView = tab === TaskView.PAY;

  const unclaimedArr = useMemo(
    () =>
      unclaimedInvoices.sort((a, b) => {
        switch (sortBy) {
          case SortBy.DATE:
            return compareDates(a, b);
          case SortBy.DESC:
            return compareVendorNames(a, b);
          default:
            return 0;
        }
      }),
    [sortBy, unclaimedInvoices],
  );

  const unpaidArr = useMemo(
    () =>
      unpaidInvoices
        .filter(inv => (showOnlyClaimed ? inv.is_claimed : true))
        .sort((a, b) => {
          switch (sortBy) {
            case SortBy.DATE:
              return compareDates(a, b);
            case SortBy.DESC:
              return compareVendorNames(a, b);
            default:
              return 0;
          }
        }) || [],
    [showOnlyClaimed, sortBy, unpaidInvoices],
  );

  const unclaimed = unclaimedArr.length || 0;
  const unpaid = unpaidArr.length || 0;

  const isLoading = [isLoadingInvoices].some(Boolean);

  const editClaimForm = useForm<EditClaimSchema>({
    defaultValues: transformInvoices(claimSelectedIds, unclaimedArr, TaskView.CLAIM),
    resolver: yupResolver(editClaimSchema),
  });

  const editPaidForm = useForm<EditPaySchema>({
    defaultValues: transformInvoices(paySelectedIds, unpaidArr, TaskView.PAY),
    resolver: yupResolver(editPaySchema),
  });

  // Unselect all
  const handleUnselectAll = useCallback(() => {
    if (isClaimView) {
      setClaimSelectedIds([]);
    }
    if (isPayView) {
      setPaySelectedIds([]);
    }
  }, [isClaimView, isPayView]);

  // Mark is claimed/paid
  const handleToggleDocumentView = useCallback(() => {
    if (documentView === DocumentView.DETAILS) {
      return setDocumentView(DocumentView.EDITOR);
    }
    return setDocumentView(DocumentView.DETAILS);
  }, [documentView]);

  // Remove from list
  const handleRemoveFromList = useCallback(async () => {
    const ids = isClaimView ? claimSelectedIds : paySelectedIds;
    if (ids.length < 1) {
      return;
    }
    try {
      const arr = isClaimView ? unclaimedArr : unpaidArr;

      const createRemoveFromListPromises = arr
        .filter(inv => ids.includes(inv.id))
        .map(inv =>
          invoicePartialUpdateMutation({
            id: inv.id,
            patchedInvoiceUpdateRequest: {
              ...(isClaimView && { hide_is_claimed: true }),
              ...(isPayView && { hide_is_paid: true }),
            },
          }).unwrap(),
        );

      await Promise.all(createRemoveFromListPromises);
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      // toggle bottom bar off
      handleUnselectAll();
    }
  }, [
    claimSelectedIds,
    handleUnselectAll,
    invoicePartialUpdateMutation,
    isClaimView,
    isPayView,
    paySelectedIds,
    snackbar,
    unclaimedArr,
    unpaidArr,
  ]);

  /**
   * Modifies invoices to be claimed or paid depending on tab aka view
   * @throws {Error} Throws an error if update mutation fails
   * @example
   * // Example usage in a component
   * handleMarkInvoices()
   */
  const handleMarkInvoices = useCallback(async () => {
    const idsArr = isClaimView ? claimSelectedIds : paySelectedIds;
    if (idsArr.length < 1) return;

    try {
      setIsLoadingMarkInvoices(true);

      const formFields = isClaimView ? editClaimForm.getValues().data : editPaidForm.getValues().data;
      const createMarkEventsPromises = idsArr.map(invoiceId => {
        const field = formFields?.find(f => f.id === invoiceId);

        const { dateValue, detailsValue } = isClaimView
          ? {
              dateValue: (field as EditClaim | undefined)?.claimDate,
              detailsValue: (field as EditClaim | undefined)?.claimNumber,
            }
          : {
              dateValue: (field as EditPay | undefined)?.payDate,
              detailsValue: (field as EditPay | undefined)?.payNumber,
            };

        const payload = {
          invoiceId,
          invoiceEventRequest: {
            type: isClaimView ? ('claim_submitted' as InvoiceEventTypeEnum) : ('invoice_paid' as InvoiceEventTypeEnum),
            is_manual: true,
            ...(dateValue && { date: format(dateValue, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") }), // Claim / Paid date
            ...(detailsValue && { details: detailsValue }), // Claim ID / Paid number
          },
        };

        return createEventMutation(payload).unwrap();
      });

      await Promise.all(createMarkEventsPromises);
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      setIsLoadingMarkInvoices(false);
      if (isClaimView) {
        setClaimSelectedIds([]);
      }
      if (isPayView) {
        setPaySelectedIds([]);
      }
    }
  }, [
    claimSelectedIds,
    createEventMutation,
    editClaimForm,
    editPaidForm,
    isClaimView,
    isPayView,
    paySelectedIds,
    snackbar,
  ]);

  const handleSortByBtnGroup = useCallback((e: MouseEvent<HTMLElement>, sort: SortBy) => {
    e.preventDefault();
    if (sort !== null) {
      setSortBy(sort);
    }
  }, []);

  const handleSortDefault = useCallback(() => setSortBy(SortBy.NONE), []);
  const handleSortByName = useCallback(() => setSortBy(SortBy.DESC), []);
  const handleSortByDate = useCallback(() => setSortBy(SortBy.DATE), []);

  const totalSelectedCost =
    tab === TaskView.PAY
      ? getTotalSelectedCost(paySelectedIds, unpaidArr)
      : getTotalSelectedCost(claimSelectedIds, unclaimedArr);

  const handleRedirectToInvoice = useCallback(
    (invoiceId: number) => () => {
      window.location.assign(`/${ROUTING.INVOICES}/${invoiceId}`);
    },
    [],
  );

  const handleCheckbox = useCallback(
    (id: number) => {
      if (isClaimView && (claimSelectedIds as number[]).includes(id)) {
        return setClaimSelectedIds(claimSelectedIds.filter(i => i !== id));
      }
      if (isClaimView && !(claimSelectedIds as number[]).includes(id)) {
        return setClaimSelectedIds(prev => [...prev, id]);
      }
      if (isPayView && (paySelectedIds as number[]).includes(id)) {
        return setPaySelectedIds(paySelectedIds.filter(i => i !== id));
      }
      if (isPayView && !(paySelectedIds as number[]).includes(id)) {
        return setPaySelectedIds(prev => [...prev, id]);
      }
    },
    [claimSelectedIds, isClaimView, isPayView, paySelectedIds],
  );

  const getCheckboxState = useCallback(
    (id: number) => {
      if (!id) return false;

      return isClaimView
        ? (claimSelectedIds as number[]).includes(id)
        : isPayView
          ? (paySelectedIds as number[]).includes(id)
          : false;
    },
    [claimSelectedIds, isClaimView, isPayView, paySelectedIds],
  );

  const handleSameDate = useCallback(
    (selectedDate: Date) => {
      const currentValues = isClaimView ? editClaimForm.getValues() : editPaidForm.getValues();

      const updatedFormData = {
        data: currentValues.data?.map(item => ({
          ...item,
          ...(isClaimView && { claimDate: selectedDate }),
          ...(isPayView && { payDate: selectedDate }),
        })),
      };

      return isClaimView ? editClaimForm.reset(updatedFormData) : editPaidForm.reset(updatedFormData);
    },
    [editClaimForm, editPaidForm, isClaimView, isPayView],
  );

  const showSpeedDial =
    (isClaimView && claimSelectedIds.length < 1 && unclaimed > 0) ||
    (isPayView && paySelectedIds.length < 1 && unpaid > 0);

  const toggleShowOnlyClaimed = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setShowOnlyClaimed(ev.target.checked);
      if (paySelectedIds.length > 0) {
        setPaySelectedIds([]);
      }
    },
    [paySelectedIds.length],
  );

  const handleChangeTab = useCallback(
    (ev: SyntheticEvent<Element, Event>, val: TaskView) => {
      handleChange(ev, val);
      handleUnselectAll();
      setDocumentView(DocumentView.DETAILS);
    },
    [handleChange, handleUnselectAll],
  );

  useEffect(() => {
    if (isClaimView) {
      const newValues = transformInvoices(claimSelectedIds, unclaimedArr, TaskView.CLAIM);
      editClaimForm.reset(newValues);
    }
    if (isPayView) {
      const newValues = transformInvoices(paySelectedIds, unpaidArr, TaskView.PAY);
      editPaidForm.reset(newValues);
    }
  }, [
    claimSelectedIds,
    unclaimedArr,
    editClaimForm,
    tab,
    editPaidForm,
    paySelectedIds,
    unpaidArr,
    isClaimView,
    isPayView,
  ]);

  return {
    handleChangeView: handleChangeTab,
    view: tab as TaskView,
    unclaimed,
    unpaid,
    isLoading,
    handleUnselectAll,
    handleToggleDocumentView,
    handleRemoveFromList,
    claimSelectedIds,
    paySelectedIds,
    handleSortBy: handleSortByBtnGroup,
    sortBy,
    totalSelectedCost,
    documentView,
    unclaimedArr,
    unpaidArr,
    handleRedirectToInvoice,
    handleCheckbox,
    getCheckboxState,
    handleSameDate,
    isLoadingPartialUpdate,
    handleMarkInvoices,
    editClaimForm,
    isLoadingMarkInvoices,
    handleSortDefault,
    handleSortByName,
    handleSortByDate,
    showSpeedDial,
    editPaidForm,
    toggleShowOnlyClaimed,
    showOnlyClaimed,
  };
};

export const useTasksContext = () => {
  const context = useContext(TasksContext);
  if (!context) throw new Error('useTasksContext must be inside TasksProvider');
  return context;
};

export const TasksProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useTasksContextValue();
  return <TasksContext.Provider value={value}>{children}</TasksContext.Provider>;
};
