import { ComponentType, FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthUserDestroyMutation, useAuthUserFeedbackCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';
import ConfirmStep from '@pages/Settings/components/AccountTab/components/DeleteFlow/components/ConfirmStep';
import FeedbackStep from '@pages/Settings/components/AccountTab/components/DeleteFlow/components/FeedbackStep';
import NotifyStep from '@pages/Settings/components/AccountTab/components/DeleteFlow/components/NotifyStep';
import { StepProps } from '@pages/Settings/components/AccountTab/components/DeleteFlow/types';
import { StyledLoadingButton, StyledTextButton } from '@pages/Settings/components/AccountTab/styled';
import { DeleteSteps } from '@pages/Settings/constants/enums';
import { useSettingsContext } from '@pages/Settings/context';
import { ErrorObj } from '@shared/types';
import { deleteSuccess } from '@store/deleteSlice';
import { RootState } from '@store/store';
import { getErrorKeys, getErrorText } from '@utils/getError';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, Typography } from '@mui/material';

const stepMap: Record<DeleteSteps, ComponentType<StepProps>> = {
  [DeleteSteps.FIRST]: NotifyStep,
  [DeleteSteps.SECOND]: FeedbackStep,
  [DeleteSteps.THIRD]: ConfirmStep,
};

const renderStep = (step: DeleteSteps, handleBack: VoidFunction) => {
  const StepComponent = stepMap[step];
  return <StepComponent handleBack={handleBack} />;
};

const isDeleteButtonDisabled = (
  currentStep: DeleteSteps,
  checkboxVal: boolean,
  password: string,
  isError: boolean | null,
): boolean => {
  if (currentStep !== DeleteSteps.THIRD) return false;
  if (!checkboxVal) return true;
  if (password.length === 0) return true;
  return isError === null;
};

const DeleteFlow: FC = () => {
  const [currentStep, setCurrentStep] = useState(DeleteSteps.FIRST);
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();
  const { toggleDeleteFlow } = useSettingsContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteState = useSelector((state: RootState) => state.delete);
  const { isError, checkboxVal, password } = deleteState;
  const snackbar = useSnackbar();
  const [deleteAccountMutation, { isLoading: isLoadingDestroy }] = useAuthUserDestroyMutation();
  const [createFeedbackMutation, { isLoading: isLoadingFeedback }] = useAuthUserFeedbackCreateMutation();

  const handleBack = useCallback(() => {
    if (currentStep === DeleteSteps.THIRD) {
      return setCurrentStep(DeleteSteps.SECOND);
    }
    if (currentStep === DeleteSteps.SECOND) {
      return setCurrentStep(DeleteSteps.FIRST);
    }
    toggleDeleteFlow();
  }, [currentStep, toggleDeleteFlow]);

  const handleNextStep = useCallback(() => {
    if (currentStep === DeleteSteps.SECOND) {
      return setCurrentStep(DeleteSteps.THIRD);
    }
    setCurrentStep(DeleteSteps.SECOND);
  }, [currentStep]);

  const handleDeleteAccount = async () => {
    try {
      if (deleteState.feedback || deleteState.option) {
        const feedbackResponse = await createFeedbackMutation({
          userFeedbackRequest: { body: deleteState.feedback || '', feedback_type: deleteState.option || '' },
        });
        if (feedbackResponse.error) {
          return snackbar.enqueueSnackbar(
            getErrorMessage(feedbackResponse.error, getErrorText(getErrorKeys(feedbackResponse.error as ErrorObj))),
            { variant: ERROR },
          );
        }
      }

      const response = await deleteAccountMutation();
      if (response.error) {
        return snackbar.enqueueSnackbar(
          getErrorMessage(response.error, getErrorText(getErrorKeys(response.error as ErrorObj))),
          { variant: ERROR },
        );
      }

      if (currentStep === DeleteSteps.THIRD) {
        dispatch(deleteSuccess());
        return navigate(`/${ROUTING.ACCOUNT_DELETED}`);
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, getErrorText(getErrorKeys(err as ErrorObj))), { variant: ERROR });
    }
  };

  const isLoading = [isLoadingFeedback, isLoadingDestroy].some(Boolean);

  return (
    <Box
      width="100%"
      height="100%"
      p={{
        xs: '40px 16px',
        sm: '40px 20px',
        md: '40px 30px',
        lg: '40px auto',
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxWidth={{
        xs: '100%',
        lg: '750px',
      }}
    >
      <StyledTextButton
        disableRipple
        variant="text"
        onClick={handleBack}
        startIcon={<ChevronLeftIcon color="primary" />}
        style={{ alignSelf: 'start' }}
      >
        <Typography>{t('common.back')}</Typography>
      </StyledTextButton>

      {renderStep(currentStep, handleBack)}

      <Box
        display="flex"
        flexDirection={downSm ? 'column' : 'row'}
        gap={5}
        justifyContent="space-between"
        width="100%"
        mt="56px"
      >
        <Button
          disableRipple
          variant="outlined"
          onClick={toggleDeleteFlow}
          sx={{
            padding: {
              xs: '8px 122px',
              sm: '8px 22px',
            },
            boxShadow: 'none',
          }}
        >
          <Typography>{t('deleteFlow.buttons.keepAccount')}</Typography>
        </Button>
        <StyledLoadingButton
          disableRipple
          variant="contained"
          color="error"
          onClick={currentStep === DeleteSteps.THIRD ? handleDeleteAccount : handleNextStep}
          disabled={isDeleteButtonDisabled(currentStep, checkboxVal, password, isError)}
          loading={isLoading}
        >
          <Typography>
            {t(
              `deleteFlow.buttons.${currentStep === DeleteSteps.THIRD ? 'permanentlyDelete' : 'continueWithDeletion'}`,
            )}
          </Typography>
        </StyledLoadingButton>
      </Box>
    </Box>
  );
};

export default memo(DeleteFlow);
