import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiInvoicesEventsDestroyApiArg } from '@api/api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { StyledDeleteEventTitleBox, StyledIconButton, StyledModal } from '../styled';

interface DeleteDueDateModalProps {
  deleteMutation: (payload: ApiInvoicesEventsDestroyApiArg) => Promise<void>;
  invoiceId: number;
  id: number;
  taskLabel: string;
  eventTitle: string;
}

const DeleteDueDateModal = NiceModal.create(
  ({ deleteMutation, invoiceId, id, taskLabel, eventTitle }: DeleteDueDateModalProps) => {
    const { t } = useTranslation();
    const modal = useModal();
    const { downSm } = useBreakpoints();

    const [isLoading, setIsLoading] = useState(false);

    const handleConfirmClick = async () => {
      setIsLoading(true);

      await deleteMutation({ invoiceId, id });
      modal.resolve(true);
      setIsLoading(false);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
        <StyledDeleteEventTitleBox display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontFamily="WFVisualSans">
            {t(eventTitle)}
          </Typography>

          <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#663C00' } }}>
            <CloseIcon />
          </StyledIconButton>
        </StyledDeleteEventTitleBox>
        <Box padding="24px 20px 30px">
          <Typography fontWeight={400}>{t(taskLabel)}</Typography>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between" p="20px">
          <StyledTextButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="text"
            color="primary"
            onClick={handleCancelClick}
            sx={{ height: 36 }}
          >
            <Typography fontSize={14}>{t('common.cancel')}</Typography>
          </StyledTextButton>
          <LoadingButton
            disableRipple
            disableElevation
            disableTouchRipple
            disableFocusRipple
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={handleConfirmClick}
            sx={{ height: 36, maxWidth: 98, boxShadow: 2 }}
          >
            <Typography fontSize={14}>{t('common.delete')}</Typography>
          </LoadingButton>
        </Box>
      </StyledModal>
    );
  },
);

export const DeleteDueDateModalId = 'DeleteDueDateModal';

NiceModal.register(DeleteDueDateModalId, DeleteDueDateModal);
