import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import UploadNotifications from '@pages/Home/components/HomeNotifications/UploadNotifications';
import { useHomeContext } from '@pages/Home/context';

import { Box, Typography } from '@mui/material';

import NoInvoices from './NoInvoices';
import NotificationsList from './NotificationsList';

const HomeNotifications = () => {
  const { t } = useTranslation();
  const { invoices, isLoadingInvoices } = useHomeContext();
  const { hasInvoices } = useUser();
  const noNewInvoices = !invoices.length && !isLoadingInvoices;

  return (
    <Box>
      <Typography mb={4} component="h6" variant="h3" fontSize={20}>
        {t('home.newToYourCapsure')}
      </Typography>

      {!hasInvoices ? <UploadNotifications /> : noNewInvoices ? <NoInvoices /> : <NotificationsList />}
    </Box>
  );
};

export default memo(HomeNotifications);
