import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InvoiceRead } from '@api/api';
import { MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useMenuActions } from '@hooks/useMenuActions';
import { StyledActionsMenu, StyledActionsMenuItem } from '@pages/InvoiceDetails/components/NotesTab/styled';
import { StyledChip, StyledOutlinedDarkChip, StyledSecondaryChip } from '@pages/InvoiceDetails/components/styled';
import { useEvents } from '@pages/InvoiceDetails/components/TimelineTab/hooks/useEvents';
import { StyledErrorDueDateChip } from '@pages/InvoiceDetails/components/TimelineTab/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { getDueDaysLeft, truncateText } from '@pages/InvoiceDetails/utils';
import { formatCurrency } from '@utils/formatCurrency';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const DetailsHeader: FC<{ data?: InvoiceRead; actionRequired: boolean }> = ({ data, actionRequired }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { anchorEl, open, handleClose, handleOpen } = useMenuActions();
  const { eventsList } = useInvoiceDetailsNewContext();
  const { editEvent, addEvent, deleteTimelineEntry, eventsListToRender } = useEvents(eventsList);

  const { downMd, upLg, downSm } = useBreakpoints();
  const handleBack = () => navigate(-1);

  const isClaimedId = eventsList?.find(ev => ev.type === 'claim_submitted')?.id;
  const isPaidId = eventsList?.find(ev => ev.type === 'invoice_paid')?.id;
  const isPaymentDue = eventsList?.find(ev => ev.type === 'payment_due_date');
  const dueDateDetails = eventsListToRender.find(
    event => event.iconType === 'payment_due_date',
  )?.detailsFieldDescription;

  return (
    <Box
      m={0}
      p={0}
      style={{
        position: 'sticky',
        top: !downMd ? '78px' : '0px',
        zIndex: 2000,
        backgroundColor: 'white',
        boxShadow: '0px 3px 4px -1px #00000033, 0px 6px 4px 0px #00000024, 0px 1px 1px 0px #0000001F',
      }}
    >
      <Box
        p={upLg ? '10px 80px 20px' : '10px 20px 20px'}
        px={upLg ? '80px' : downMd ? '20px' : '30px'}
        minHeight={!downSm ? '90px' : '0px'}
      >
        <StyledTextButton
          onClick={handleBack}
          aria-label="back button"
          disableRipple
          variant="text"
          sx={{ height: '26px', margin: 0 }}
        >
          <Box display="flex" alignItems="center" gap={0.5} ml="-4px">
            <ChevronLeftIcon />{' '}
            <Typography fontSize={16} fontWeight={500}>
              {t('common.back')}
            </Typography>
          </Box>
        </StyledTextButton>

        <Box
          display="flex"
          flexDirection={downMd ? 'column' : 'row'}
          gap={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography fontSize={20} mt="14px" fontFamily="WFVisualSans" sx={{ overflowWrap: 'break-word' }}>
            <Typography component="span" fontWeight={500} fontSize={20} fontFamily="WFVisualSans">
              {downMd ? truncateText(MAX_DESCRIPTION_CHARACTERS, data?.description) : data?.description}
            </Typography>{' '}
            <Typography component="span" fontSize={20} fontWeight={400} fontFamily="WFVisualSans">
              for
            </Typography>{' '}
            <Typography component="span" fontSize={20} fontWeight={500} fontFamily="WFVisualSans">
              ${formatCurrency(data?.total_amount)}
            </Typography>
          </Typography>

          <Box
            display="flex"
            gap="4px"
            alignItems="center"
            justifyContent="space-between"
            height={30}
            width={downMd ? '100%' : 'auto'}
          >
            {
              <Box aria-label="action-slots-container" display="flex" gap="12px">
                {/* SLOT_1 */}
                {actionRequired ? (
                  <StyledSecondaryChip
                    color="secondary"
                    variant="filled"
                    label={
                      downSm
                        ? t('dashboard.invoiceDetails.incomplete')
                        : t('dashboard.invoiceDetails.incompleteDetails')
                    }
                  />
                ) : (
                  !data?.is_claimed && (
                    <StyledChip color="info" variant="outlined" label={t('dashboard.invoiceDetails.readyToClaim')} />
                  )
                )}

                {/* SLOT_2 */}
                {data?.is_claimed ? (
                  <StyledChip
                    variant="filled"
                    color="primary"
                    isDark
                    label={t('dashboard.invoiceDetails.claimed')}
                    icon={<CheckIcon />}
                  />
                ) : (
                  actionRequired && (
                    <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notClaimed')} />
                  )
                )}

                {/* SLOT_3 */}
                {data?.is_paid ? (
                  <StyledChip
                    isDark
                    color="primary"
                    variant="filled"
                    label={t('dashboard.invoiceDetails.paid')}
                    icon={<CheckIcon />}
                  />
                ) : !data?.due_date ? (
                  <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
                ) : downSm ? (
                  getDueDaysLeft(data?.due_date) === 0 ? (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.dueToday')}
                      color="error"
                      variant="outlined"
                    />
                  ) : getDueDaysLeft(data?.due_date) > 0 && getDueDaysLeft(data?.due_date) <= 10 ? (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.dueSoon')}
                      color="error"
                      variant="outlined"
                    />
                  ) : getDueDaysLeft(data?.due_date) > 10 ? (
                    <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
                  ) : (
                    <StyledErrorDueDateChip
                      label={t('dashboard.invoiceDetails.overdue')}
                      color="error"
                      variant="filled"
                    />
                  )
                ) : getDueDaysLeft(data?.due_date) === 0 ? (
                  <StyledErrorDueDateChip
                    label={t('dashboard.invoiceDetails.paymentDueToday')}
                    color="error"
                    variant="outlined"
                  />
                ) : getDueDaysLeft(data?.due_date) > 0 && getDueDaysLeft(data?.due_date) <= 10 ? (
                  <StyledErrorDueDateChip
                    label={t('common.dueIn', {
                      days: `${getDueDaysLeft(data?.due_date)} ${
                        Math.abs(getDueDaysLeft(data?.due_date)) > 1
                          ? `${t('common.days')}`
                          : `${t('common.days').slice(0, -1)}`
                      }`,
                    })}
                    color="error"
                    variant="outlined"
                  />
                ) : getDueDaysLeft(data?.due_date) > 10 ? (
                  <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
                ) : (
                  <StyledErrorDueDateChip
                    label={`${t('common.overdue', {
                      days: Math.abs(getDueDaysLeft(data?.due_date)),
                    })} ${
                      Math.abs(getDueDaysLeft(data?.due_date)) > 1 ? t('common.days') : t('common.days').slice(0, -1)
                    }`}
                    color="error"
                    variant="filled"
                  />
                )}
              </Box>
            }
            <IconButton disableRipple color="primary" onClick={handleOpen}>
              <MoreVertIcon />
            </IconButton>
            {/* DROPDOWN OPTIONS */}
            <StyledActionsMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{ 'aria-labelledby': 'actions-button' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {/* MARK AS CLAIMED / NOT CLAIMED */}
              <StyledActionsMenuItem
                onClick={() => {
                  handleClose();
                  if (isClaimedId) {
                    return deleteTimelineEntry({
                      eventId: isClaimedId,
                      eventTitle: t('dashboard.timeline.checkboxes.submitClaimLabel'),
                      taskLabel: t('dashboard.timeline.claimSubmitted'),
                      eventType: 'claim_submitted',
                    });
                  }
                  addEvent('claim_submitted');
                }}
              >
                {isClaimedId ? <RemoveCircleOutlineIcon color="action" /> : <DoneIcon color="action" />}
                <Typography fontWeight={400}>
                  {isClaimedId ? t('dashboard.timeline.markAsNotClaimed') : t('dashboard.timeline.markAsClaimed')}
                </Typography>
              </StyledActionsMenuItem>
              <Divider />
              {/* MARK AS PAID / NOT PAID */}
              <StyledActionsMenuItem
                onClick={() => {
                  handleClose();
                  if (isPaidId) {
                    return deleteTimelineEntry({
                      eventId: isPaidId,
                      eventTitle: t('dashboard.timeline.checkboxes.payProviderLabel'),
                      taskLabel: t('dashboard.timeline.providerPaid'),
                      eventType: 'invoice_paid',
                    });
                  }
                  addEvent('invoice_paid');
                }}
              >
                {isPaidId ? <RemoveCircleOutlineIcon color="action" /> : <DoneIcon color="action" />}
                <Typography fontWeight={400}>
                  {isPaidId ? t('dashboard.timeline.markAsNotPaid') : t('dashboard.timeline.markAsPaid')}
                </Typography>
              </StyledActionsMenuItem>
              <Divider />
              {/* ADD / EDIT DUE DATE */}
              <StyledActionsMenuItem
                onClick={() => {
                  handleClose();
                  if (isPaymentDue?.date) {
                    return editEvent({
                      eventId: isPaymentDue?.id,
                      eventName: t('dashboard.timeline.editDueDate'),
                      eventType: 'payment_due_date',
                      eventDate: isPaymentDue?.date,
                      eventDetails: dueDateDetails || '',
                    });
                  }
                  addEvent('payment_due_date');
                }}
              >
                {isPaymentDue?.date ? <EditIcon color="action" /> : <AddIcon color="action" />}
                <Typography fontWeight={400}>
                  {isPaymentDue?.date ? t('dashboard.timeline.editDueDate') : t('dashboard.timeline.addDueDate')}
                </Typography>
              </StyledActionsMenuItem>
              {isPaymentDue?.date && <Divider />}
              {/* DELETE DUE DATE */}
              {isPaymentDue?.date && (
                <StyledActionsMenuItem
                  onClick={() => {
                    handleClose();
                    deleteTimelineEntry({
                      eventId: isPaymentDue?.id,
                      eventTitle: t('dashboard.timeline.deleteDueDate'),
                      taskLabel: t('dashboard.timeline.deleteDueDateQuestion'),
                      isDeleteDueDate: true,
                    });
                  }}
                >
                  <DeleteIcon color="action" />
                  <Typography fontWeight={400}>{t('dashboard.timeline.deleteDueDate')}</Typography>
                </StyledActionsMenuItem>
              )}
            </StyledActionsMenu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(DetailsHeader);
