import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useMenuActions } from '@hooks/useMenuActions';
import {
  StyledActionsMenu,
  StyledActionsMenuItem,
  StyledNoteBox,
  StyledNoteDateChip,
} from '@pages/InvoiceDetails/components/NotesTab/styled';
import { SingleNote } from '@pages/InvoiceDetails/components/NotesTab/types';
import { convertIsoToReadable } from '@utils/formatTime';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import 'react-quill/dist/quill.bubble.css';
import './QuillEditor.css';

const getResponsiveMaxWidth = (breakpoints: { upLg: boolean; downLg: boolean; downMd: boolean; downSm: boolean }) => {
  const sizeMaxWidth = {
    upLg: '505px',
    downLg: '405px',
    downMd: '100%',
    downSm: '100%',
  };

  if (breakpoints.downSm) return sizeMaxWidth.downSm;
  if (breakpoints.downMd) return sizeMaxWidth.downMd;
  if (breakpoints.downLg) return sizeMaxWidth.downLg;
  if (breakpoints.upLg) return sizeMaxWidth.upLg;

  return 'none';
};

const Note: FC<SingleNote> = ({ created_at, text, id, activateEdit, handleDelete, loadingDelete }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const { anchorEl, open, handleClose, handleOpen } = useMenuActions();

  return (
    <StyledNoteBox maxWidth={getResponsiveMaxWidth(breakpoints)}>
      <Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <StyledNoteDateChip
            label={convertIsoToReadable(created_at, { fullYear: true, monthFormat: 'MMMM' })}
            size="medium"
            variant="filled"
          />
          <IconButton onClick={handleOpen}>
            <MoreVertIcon color="primary" />
          </IconButton>
        </Box>
        <ReactQuill readOnly value={text} theme="bubble" className="ql-readonly" />

        {/* MENU OPENED STATE */}
        <StyledActionsMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'actions-button' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <StyledActionsMenuItem
            onClick={() => {
              handleClose();
              activateEdit(id);
            }}
          >
            <EditIcon color="action" />
            <Typography fontWeight={400}>{t('common.edit')}</Typography>
          </StyledActionsMenuItem>
          <Divider />
          <StyledActionsMenuItem
            onClick={() => {
              handleClose();
              handleDelete(id);
            }}
            disabled={loadingDelete}
          >
            <DeleteIcon color="action" />
            <Typography fontWeight={400}>{t('common.delete')}</Typography>
          </StyledActionsMenuItem>
        </StyledActionsMenu>
      </Box>
    </StyledNoteBox>
  );
};

export default memo(Note);
