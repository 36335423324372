import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthUserFeedbackCreateMutation } from '@api/api';
import { ERROR, SUCCESS } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTextButton } from '@pages/Invoices/styled';
import { StyledTextarea } from '@providers/layouts/AppLayout/styled';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

import { StyledIconButton, StyledModal, StyledModalTitleBox } from '@/shared/styles';

const feedbackTypes = [
  {
    label: 'dashboard.navigation.modal.types.featureSuggestion',
    id: 1,
  },
  {
    label: 'dashboard.navigation.modal.types.reportBug',
    id: 2,
  },
  {
    label: 'dashboard.navigation.modal.types.uX',
    id: 3,
  },
  {
    label: 'dashboard.navigation.modal.types.supportX',
    id: 4,
  },
  {
    label: 'dashboard.navigation.modal.types.pricing',
    id: 5,
  },
  {
    label: 'dashboard.navigation.modal.types.contentDoc',
    id: 6,
  },
  {
    label: 'dashboard.navigation.modal.types.general',
    id: 7,
  },
];

const MAX_FEEDBACK_CHARS = 1000;
const trimFieldValue = (value: string) => value.slice(0, MAX_FEEDBACK_CHARS);

const FeedbackModal = NiceModal.create(() => {
  const { t } = useTranslation();
  const modal = useModal();
  const { downSm } = useBreakpoints();
  const snackbar = useSnackbar();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      type: '',
      body: '',
    },
  });

  const [createFeedbackMutation, { isLoading: isLoadingFeedback }] = useAuthUserFeedbackCreateMutation();

  const bodyText = watch('body', '');
  const charactersUsed = bodyText?.length || 0;

  const handleConfirmClick = handleSubmit(async ({ body, type }) => {
    try {
      const res = await createFeedbackMutation({
        userFeedbackRequest: { body, feedback_type: type },
      });
      if (!res.error) {
        modal.resolve(true);
        modal.remove();
        snackbar.enqueueSnackbar(t('dashboard.navigation.modal.feedbackSubmitted'), { variant: SUCCESS });
      }
      if (res.error) {
        modal.resolve(false);
        modal.remove();
        // @ts-ignore
        snackbar.enqueueSnackbar(res.error.data.error.detail, { variant: ERROR });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  });
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'medium'} onClose={handleCancelClick}>
      <StyledModalTitleBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontFamily="WFVisualSans">
          {t('dashboard.navigation.modal.feedback')}
        </Typography>
        <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
          <CloseIcon />
        </StyledIconButton>
      </StyledModalTitleBox>

      <Box padding="24px 20px 30px">
        <Typography fontWeight={400} mb={2}>
          {t('dashboard.navigation.modal.feedbackSubtitle')}
        </Typography>

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-feedback-type">
                <Typography fontWeight={400}>{t('dashboard.navigation.modal.feedbackType')}</Typography>
              </InputLabel>
              <Select
                {...field}
                labelId="select-feedback-type-label"
                id="select-feedback-type"
                label={t('dashboard.navigation.modal.feedbackType')}
              >
                {feedbackTypes.map(({ label, id }) => (
                  <MenuItem key={id} value={t(label)}>
                    <Typography>{t(label)}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Box display="flex" alignItems="center" mt={3}>
          <Controller
            name="body"
            control={control}
            rules={{ maxLength: MAX_FEEDBACK_CHARS }}
            render={({ field }) => (
              <FormControl fullWidth>
                <StyledTextarea
                  {...field}
                  maxRows={10}
                  minRows={4}
                  placeholder={t('common.feedback')}
                  onChange={ev => field.onChange(trimFieldValue(ev.target.value))}
                />
                <Box display="flex" flexDirection="row-reverse">
                  <FormHelperText>
                    {charactersUsed} / {MAX_FEEDBACK_CHARS}
                  </FormHelperText>
                </Box>
              </FormControl>
            )}
          />
        </Box>
      </Box>

      <Divider />
      <Box display="flex" justifyContent="space-between" p="20px">
        <StyledTextButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          variant="text"
          onClick={handleCancelClick}
          sx={{ height: 36, p: '4px' }}
        >
          <Typography fontSize={14}>{t('common.cancel')}</Typography>
        </StyledTextButton>
        <LoadingButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          loading={isLoadingFeedback}
          onClick={handleConfirmClick}
          sx={{ height: 36 }}
        >
          <Typography fontSize={14}>{t('dashboard.navigation.modal.sendFeedback')}</Typography>
        </LoadingButton>
      </Box>
    </StyledModal>
  );
});

export const FeedbackModalId = 'FeedbackModal';

NiceModal.register(FeedbackModalId, FeedbackModal);
