import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import InvoiceEventTimeline from '@pages/InvoiceDetails/components/TimelineTab/components/InvoiceEventTimeline';
import { useEvents } from '@pages/InvoiceDetails/components/TimelineTab/hooks/useEvents';
import { StyledTimeline } from '@pages/InvoiceDetails/components/TimelineTab/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetails/context';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';

const TimelineTab: FC = () => {
  const { t } = useTranslation();
  const { downMd } = useBreakpoints();
  const { eventsList } = useInvoiceDetailsNewContext();
  const { eventsListToRender, addEvent } = useEvents(eventsList);

  const isPaymentDue = eventsList?.find(ev => ev.type === 'payment_due_date');

  return (
    <Box width="100%" height="100%">
      <Box
        display="flex"
        flexDirection="column"
        pb={{
          xs: '120px',
          sm: '90px',
          md: '105px',
        }}
        gap={downMd ? '40px' : '30px'}
      >
        {/* TIMELINE COLUMN */}
        <Box>
          <Box mb={2} display="flex" alignItems="center" height={50}>
            <Typography fontFamily="WFVisualSans" fontSize={20}>
              {t('dashboard.timeline.timelineTitle')}
            </Typography>
          </Box>
          <StyledTimeline>
            {eventsListToRender.map((timelineEvent, idx) => (
              <InvoiceEventTimeline
                key={timelineEvent.id}
                {...timelineEvent}
                isLast={idx === eventsListToRender.length - 1}
              />
            ))}
          </StyledTimeline>
        </Box>

        <Box
          width={{
            xs: '100%',
            md: '366px',
          }}
          mt="2px"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          {!isPaymentDue?.date && (
            <Button
              disableRipple
              disableTouchRipple
              disableFocusRipple
              variant="outlined"
              color="primary"
              endIcon={<AddIcon />}
              onClick={() => addEvent('payment_due_date')}
            >
              <Typography>{t('dashboard.timeline.buttons.addDueDate')}</Typography>
            </Button>
          )}
          <Button
            fullWidth
            disableRipple
            disableFocusRipple
            disableTouchRipple
            endIcon={<AddIcon />}
            variant="outlined"
            onClick={() => addEvent('custom_event')}
          >
            <Typography>{t('dashboard.timeline.buttons.addToTimelineText')}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TimelineTab);
