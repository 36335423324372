import { FC, memo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthSignupCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { SignupSchema } from '@pages/Signup/types';
import { signupSuccess } from '@store/authSlice';
import { getErrorKeys, getErrorText } from '@utils/getError';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { Box } from '@mui/material';

import SignUpForm from './components/SignupForm';
import { ErrorObj } from '@/shared/types';

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().minMax(8, 25).containsDigit().containsUppercase().required(),
});

const PADDING_BOTTOM = {
  xs: '81px',
  sm: '109px',
  md: '115px',
  lg: '125px',
} as const;

const SignUp: FC = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { downSm, downMd, downLg } = useBreakpoints();

  const getPb = useCallback(() => {
    if (downSm) return PADDING_BOTTOM.xs;
    if (downMd) return PADDING_BOTTOM.sm;
    if (downLg) return PADDING_BOTTOM.md;
    return PADDING_BOTTOM.lg;
  }, [downSm, downMd, downLg]);

  const form = useForm<SignupSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const [registerMutation, { isLoading: registerIsLoading }] = useAuthSignupCreateMutation();

  const dispatch = useDispatch();

  const isLoading = [registerIsLoading].some(Boolean);

  const onSignUp: SubmitHandler<SignupSchema> = useCallback(
    async ({ email, password, firstname, lastname }) => {
      try {
        const registerPayload = {
          password1: password,
          password2: password,
          first_name: firstname,
          last_name: lastname,
          email,
        };

        const regResponse = await registerMutation({ customRegisterRequest: registerPayload });
        // @ts-ignore
        if (regResponse && regResponse?.error?.status === 400) {
          snackbar.enqueueSnackbar(
            // @ts-ignore
            getErrorMessage(regResponse?.error, getErrorText(getErrorKeys(regResponse?.error?.data))),
            { variant: ERROR },
          );
        }
        if (
          regResponse &&
          // @ts-ignore
          regResponse?.error?.status === 400 &&
          // @ts-ignore
          regResponse?.error?.data?.error?.fields?.email.includes('already registered')
        ) {
          return form.setError('email', {
            type: 'manual',
            message: t('auth.userExist'),
          });
        }
        if (!regResponse.error) {
          dispatch(signupSuccess());
          navigate(`/${ROUTING.CONFIRM_EMAIL}`, { state: { email, password } });
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, getErrorText(getErrorKeys(err as ErrorObj))), { variant: ERROR });
      }
    },
    [dispatch, form, navigate, registerMutation, snackbar, t],
  );

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.signup')}</title>
      </Helmet>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        aria-label="signup page"
        minHeight={980}
        pb={getPb()}
      >
        <FormProvider {...form}>
          <form autoComplete="off" onSubmit={form.handleSubmit(onSignUp)}>
            <SignUpForm loading={isLoading} />
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default memo(SignUp);
