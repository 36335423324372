import { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '@hooks/api/useUser';
import ConfirmPassword from '@pages/Settings/components/AccountTab/components/DeleteFlow/components/ConfirmPassword';
import { useSettingsContext } from '@pages/Settings/context';
import { setPasswordInvalid, setPasswordValid, toggleCheckboxVal } from '@store/deleteSlice';
import { RootState } from '@store/store';
import debounce from 'lodash-es/debounce';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

const debouncedCheck = debounce(check => {
  check();
}, 500);

const ConfirmStep: FC = () => {
  const { t } = useTranslation();
  const { data } = useUser();
  const { checkMutation } = useSettingsContext();
  const dispatch = useDispatch();
  const deleteState = useSelector((state: RootState) => state.delete);
  const { checkboxVal, password } = deleteState;

  const handleCheckPassword = useCallback(async (): Promise<void> => {
    try {
      const res = await checkMutation({ loginRequest: { email: data!.email, password } });
      if (res?.data?.access) {
        dispatch(setPasswordValid());
        return;
      }
      dispatch(setPasswordInvalid());
    } catch (error) {
      dispatch(setPasswordInvalid());
    }
  }, [checkMutation, data, dispatch, password]);

  useEffect(() => {
    if (password.length > 0) {
      debouncedCheck(handleCheckPassword);
    }
  }, [handleCheckPassword, password.length]);

  return (
    <Box mt={3}>
      <Typography fontSize={20} fontFamily="WFVisualSans" mb={3}>
        {t('deleteFlow.stepThreeTitle')}
      </Typography>

      <Typography mt={4} mb="11px" fontWeight={400}>
        {t('deleteFlow.stepThreeSubtitle')}
      </Typography>

      <Box mb="10px">
        <ConfirmPassword />
      </Box>

      <FormControlLabel
        checked={checkboxVal}
        control={<Checkbox sx={{ paddingTop: 0 }} onChange={() => dispatch(toggleCheckboxVal())} />}
        labelPlacement="end"
        label={
          <Typography fontWeight={400} color="#000000DE" pt="9px">
            {t('deleteFlow.stepThreeCheckboxLabel')}
          </Typography>
        }
      />
    </Box>
  );
};

export default memo(ConfirmStep);
