import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { InvoiceRead } from '@api/api';
import { MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import { ROUTING } from '@constants/routing';
import NotificationChip from '@pages/Home/components/HomeNotifications/NotificationsList/NotificationChip';
import { truncateText } from '@pages/InvoiceDetails/utils';

import { Box, Typography } from '@mui/material';

import { StyledCheckbox, StyledNotificationsItemBox, StyledNotificationsItemLabel, StyledTasksLink } from './styled';

interface NotificationsItemProps {
  invoice: InvoiceRead;
  onToggleItem: () => void;
  checked: boolean;
}

const NotificationsItem: FC<NotificationsItemProps> = ({ invoice, checked, onToggleItem }) => {
  const { t } = useTranslation();

  return (
    <StyledNotificationsItemBox display="flex" p="15px" alignItems="center" gap="23px">
      <StyledNotificationsItemLabel
        checked={checked}
        control={<StyledCheckbox />}
        label=""
        onChange={e => {
          e.stopPropagation();
          onToggleItem();
        }}
      />

      <StyledTasksLink
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        component={RouterLink}
        to={`/${ROUTING.INVOICES}/${invoice.id}`}
        target="_blank"
      >
        <Box>
          <Typography fontWeight={400} mb={1}>
            <Trans
              i18nKey="home.notificationTitle"
              values={{
                description: truncateText(MAX_DESCRIPTION_CHARACTERS, invoice.description),
                totalAmount: invoice.total_amount,
              }}
              components={{
                bold: <Typography component="span" fontWeight={500} />,
              }}
            />
          </Typography>
          <Typography variant="body2">
            {t(invoice.is_manual_upload ? 'home.manuallyUploaded' : 'home.receivedByEmail')}
          </Typography>
        </Box>
        <NotificationChip viewed={!!invoice.is_viewed} />
      </StyledTasksLink>
    </StyledNotificationsItemBox>
  );
};

export default memo(NotificationsItem);
