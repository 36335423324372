import { FC, memo } from 'react';
import { StyledGridToolbarContainer } from '@pages/Invoices/styled';

import { GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';

const CustomToolbar: FC = () => (
  <StyledGridToolbarContainer>
    <GridToolbarDensitySelector
      slotProps={{
        button: { variant: 'text' },
      }}
    />
    <GridToolbarExport
      slotProps={{
        button: { variant: 'text' },
      }}
    />
  </StyledGridToolbarContainer>
);

export default memo(CustomToolbar);
