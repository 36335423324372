import { InvoiceRead } from '@api/api';
import { MAX_DESCRIPTION_CHARACTERS, MIN_CHARACTERS } from '@constants/common';
import { SELECTOR } from '@pages/Invoices/enums';
import { CalendarCell, ExtendedInvoice } from '@pages/Invoices/types';
import {
  addDays,
  addMonths,
  differenceInDays,
  eachDayOfInterval,
  format,
  formatISO,
  getDaysInMonth,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';

export const getCurrentMonthIso = (): string => {
  const now = new Date();
  return formatISO(new Date(now.getFullYear(), now.getMonth(), 1), { representation: 'date' });
};

export const getMonthWithSelectorIso = (selector: SELECTOR, selectedMonth: string): string => {
  const currentMonthDate = parseISO(selectedMonth);
  const adjustedDate = selector === SELECTOR.NEXT ? addMonths(currentMonthDate, 1) : addMonths(currentMonthDate, -1);

  return formatISO(new Date(adjustedDate.getFullYear(), adjustedDate.getMonth(), 1), { representation: 'date' });
};

export const getTodayIso = () => {
  const todayDate = startOfDay(new Date());
  return formatISO(todayDate, { representation: 'date' });
};

export const getChipColors = (isLast: boolean) =>
  isLast
    ? {
        backgroundColor: '#FFF7DC',
        borderColor: '#BA9370',
      }
    : {
        backgroundColor: '#E8FDFF',
        borderColor: '#153A60',
      };

export function getDateRange(start: string, end: string): string[] {
  const startDate = new Date(start);
  const endDate = new Date(end);

  return eachDayOfInterval({ start: startDate, end: endDate }).map(date => format(date, 'yyyy-MM-dd'));
}

export function getCurrentDayOfService(serviceDate: string, startDate: string): number {
  const serviceDateObj = new Date(serviceDate);
  const startDateObj = new Date(startDate);

  return differenceInDays(serviceDateObj, startDateObj) + 1;
}

export function getFinalDayOfService(startDate: string, endDate: string): number {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  return differenceInDays(endDateObj, startDateObj) + 1;
}

export function generateCalendarData(selectedMonth: string) {
  const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const date = parse(selectedMonth, 'yyyy-MM-dd', new Date());
  const firstDayOfMonth = startOfMonth(date);
  const daysInMonth = getDaysInMonth(date);

  // Find the day of the week index for the first day of the month (0 = MON, 6 = SUN)
  const jsDayOfWeek = firstDayOfMonth.getDay(); // 0 = SUN, 6 = SAT
  // Map to ISO: MON = 0, SUN = 6
  // Calculate how many days to include from the previous month
  const daysFromPreviousMonth = (jsDayOfWeek + 6) % 7;

  // Start with the last days of the previous month
  const prevMonthStartDate = subDays(firstDayOfMonth, daysFromPreviousMonth);
  const calendarData: CalendarCell[] = [];

  // Fill the first days of the calendar, including overflow from the previous month
  for (let i = 0; i < daysFromPreviousMonth; i++) {
    const currentDate = addDays(prevMonthStartDate, i);
    calendarData.push({
      dayOfWeek: weekdays[i],
      dayOfMonth: currentDate.getDate(),
      isDisabled: true, // previous month
      id: 0,
    });
  }

  // Fill the rest of the days in the current month
  for (let i = 0; i < daysInMonth; i++) {
    const currentDate = addDays(firstDayOfMonth, i);
    const dayIndex = (daysFromPreviousMonth + i) % 7; // Wrap around to get the day of the week
    calendarData.push({
      dayOfWeek: weekdays[dayIndex],
      dayOfMonth: currentDate.getDate(),
      isDisabled: false,
      id: 0,
    });
  }

  // Add unique ids to each item based on the index
  for (let i = 0; i < calendarData.length; i++) {
    calendarData[i].id = i + 1; // Assign unique id based on the index
  }

  return calendarData;
}

export function findSelectedCell(calendarGrid: CalendarCell[], selectedDayMoreInvoices: ExtendedInvoice[]) {
  const uniqueDaysMap = new Map<number, CalendarCell>();

  selectedDayMoreInvoices.forEach(invoice => {
    if (invoice.support_date) {
      const supportDate = new Date(invoice.support_date);
      const dayOfMonth = supportDate.getDate();

      // Find the matching calendarGrid object
      const matchingDay = calendarGrid.find(day => day.dayOfMonth === dayOfMonth && !day.isDisabled);

      if (matchingDay) {
        uniqueDaysMap.set(matchingDay.id, matchingDay);
      }
    }
  });

  return Array.from(uniqueDaysMap.values());
}

const isValidString = (str: string | undefined | null) => {
  if (!str) return false;
  if (str.length < MIN_CHARACTERS || str.length > MAX_DESCRIPTION_CHARACTERS) return false;
  return /[A-Za-z0-9]/.test(str);
};

export const getTotalIssues = (row: InvoiceRead) => {
  const validationChecks = [
    () => isValidString(row.description),
    () => Boolean(row.category?.name),
    () => isValidString(row.vendor_name),
    () => Boolean(row.participant),
    () => Boolean(row?.service_exact_date || (row?.service_start_date && row?.service_end_date)),
    () => Boolean(row.total_amount),
    () => Boolean(row.abn || row.reason?.id),
  ];

  return validationChecks.reduce((issues, check) => issues + (check() ? 0 : 1), 0);
};
