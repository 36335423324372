import { MAX_DESCRIPTION_CHARACTERS, MAX_FORMATTED_ABN_SYMBOLS, MIN_CHARACTERS } from '@constants/common';
import * as yup from 'yup';

export const updateInvoiceSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .test('missing_description', 'Enter a description of the support. Maximum 40 characters.', value => !!value)
    .min(MIN_CHARACTERS, 'The description we captured is too short. Please include at least 3 letters and/or numbers.')
    .matches(
      /[A-Za-z0-9]/,
      'The description we captured only contains special characters. Please include letters and/or numbers.',
    )
    .max(
      MAX_DESCRIPTION_CHARACTERS,
      () => 'The description we captured is too long. Please shorten to under 40 characters. ',
    ),
  total_amount: yup
    .string()
    .nullable()
    .test(
      'missing_amount_value',
      'Enter the dollar amount of the support',
      val => !(val === '' || val === '0.00' || val === '0' || val === '0,00'),
    ),
  service_exact_date: yup
    .string()
    .nullable()
    .test('check-service-exact-date', 'Enter the support date', function (value) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { support_more_date } = this.parent;

      if (!support_more_date) {
        return !!value;
      }
      return true;
    }),
  service_start_date: yup
    .string()
    .nullable()
    .test('check-service-start-date', 'Enter the support start date', function (value) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { support_more_date } = this.parent;

      if (support_more_date) {
        return !!value;
      }
      return true;
    }),
  service_end_date: yup
    .string()
    .nullable()
    .test('check-service-end-date', 'Enter the support end date', function (value) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { support_more_date } = this.parent;

      if (support_more_date) {
        return !!value;
      }
      return true;
    }),
  vendor_name: yup
    .string()
    .nullable()
    .test('missing_vendor_name', 'Enter the name of the provider. Maximum 40 characters', value => !!value)
    .min(
      MIN_CHARACTERS,
      'The provider name we captured is too short. Please include at least 3 letters and/or numbers.',
    )
    .matches(
      /[A-Za-z0-9]/,
      'The provider name we captured only contains special characters. Please include letters and/or numbers.',
    )
    .max(
      MAX_DESCRIPTION_CHARACTERS,
      () => 'The provider name we captured is too long. Please shorten to under 40 characters.',
    ),
  invoice_date: yup.date(),
  due_date: yup.string().nullable(),
  updated_at: yup.string().nullable(),
  customer_name: yup.string().nullable(),
  customer_address: yup.string().nullable(),
  abn: yup
    .string()
    .nullable()
    .test('missing_abn_value', 'ABN needs to be 11 digits long.', function (value) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { has_abn } = this.parent;

      if (has_abn) {
        return !!value && value.length >= MAX_FORMATTED_ABN_SYMBOLS;
      }
      return true;
    }),
  sub_total: yup.string().nullable(),
  total_tax: yup.string().nullable(),
  currency_symbol: yup.string().nullable(),
  currency: yup.string().nullable(),
  category: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .nullable()
    .test('missing_category', 'Select the relevant support category', value => !!value?.id),
  missing_reason: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .test(
      'missing_reason',
      'Select a reason why no provider ABN can be provided. This is an NDIS requirement.',
      function (value) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { has_abn } = this.parent;

        if (!has_abn) {
          return !!value?.id;
        }
        return true;
      },
    ),
  has_abn: yup.boolean().nullable(),
  participant: yup
    .string()
    .nullable()
    .test('missing_participant', 'Select the participant that the invoice relates to', value => !!value),
  original_filename: yup.string().nullable(),
});

export const detailsSchema = yup.object().shape({
  missing_reason: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .nullable(),
  missing_category: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string().nullable(),
      value: yup.string().nullable(),
    })
    .nullable(),
  missing_description: yup
    .string()
    .nullable()
    .test('missing_description', 'Enter a description of the support. Maximum 40 characters.', value => !!value)
    .min(MIN_CHARACTERS, 'The description we captured is too short. Please include at least 3 letters and/or numbers.')
    .matches(
      /[A-Za-z0-9]/,
      'The description we captured only contains special characters. Please include letters and/or numbers.',
    )
    .max(
      MAX_DESCRIPTION_CHARACTERS,
      () => 'The description we captured is too long. Please shorten to under 40 characters.',
    ),
  missing_vendor_name: yup
    .string()
    .nullable()
    .test('missing_vendor_name', 'Enter the name of the provider. Maximum 40 characters. ', value => !!value)
    .min(
      MIN_CHARACTERS,
      'The provider name we captured is too short. Please include at least 3 letters and/or numbers.',
    )
    .matches(
      /[A-Za-z0-9]/,
      'The provider name we captured only contains special characters. Please include letters and/or numbers.',
    )
    .max(
      MAX_DESCRIPTION_CHARACTERS,
      () => 'The provider name we captured is too long. Please shorten to under 40 characters. ',
    ),
  missing_total_amount: yup
    .string()
    .nullable()
    .test('missing_total_amount', 'Enter the dollar amount of the support', value => !!value),
  original_filename: yup.string().nullable(),
  missing_participant: yup.string().nullable(),
  service_exact_date: yup.string().nullable(),
});

export const paymentSchema = yup.object().shape({
  paid_date: yup.string().nullable(),
  paid_reference_number: yup.string().nullable(),
});

export const claimSchema = yup.object().shape({
  claimed_date: yup.string().nullable(),
  claim_id: yup.string().nullable().min(MIN_CHARACTERS, 'This field should have at least 3 digits'),
});
