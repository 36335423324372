import { LinkProps as RouterLinkProps } from 'react-router-dom';

import {
  Box,
  Checkbox,
  Chip,
  ChipProps,
  FormControlLabel,
  Link,
  LinkProps,
  Pagination,
  PaginationItem,
  PaginationItemProps,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import LoadingButton from '@components/LoadingButton';

export const StyledNotificationsItemBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.white}`,
  transition: theme.transitions.create(['background-color'], {
    duration: '0.2s',
  }),
  '&:hover': {
    backgroundColor: alpha(theme.colors.black as string, 0.1),
    '.MuiTypography-body1': {
      textDecoration: 'underline',
    },
  },
  '&:last-of-type': {
    border: 'none',
  },
}));

export const StyledNotificationsItemLabel = styled(FormControlLabel)(() => ({
  marginRight: 0,
}));

export const StyledSelectAllLabel = styled(FormControlLabel)(() => ({
  paddingLeft: '15px',
}));

export const StyledTasksLink = styled(Link)<LinkProps & RouterLinkProps>(({ theme }) => ({
  display: 'flex',
  width: '100%',
  color: theme.colors.white,
  textDecoration: 'none',
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    fill: theme.colors.white,
  },
}));

export const StyledChip = styled(Chip)<ChipProps & { viewed?: boolean }>(({ theme, viewed }) => ({
  ...(!viewed && { backgroundColor: theme.colors.orange }),
  height: '32px',

  '.MuiChip-label': {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    padding: `0 10px 0 ${viewed ? '10px' : '4px'}`,
    fontSize: '13px',
    fontWeight: 400,
    color: viewed ? theme.colors.white : theme.colors.secondary.contrast,
  },
}));

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  fontSize: 14,
  padding: '6px 16px',
  color: theme.colors.secondary.contrast,
  '&:hover': {
    color: theme.colors.white,
  },
}));

export const StyledPagination = styled(Pagination)(() => ({
  marginTop: '16px',
}));

export const StyledPaginationItem = styled(PaginationItem)<PaginationItemProps>(({ theme }) => ({
  color: theme.colors.white,

  '&.Mui-selected': {
    backgroundColor: theme.colors.white,
    color: theme.colors.primary.main,
    '&:hover': {
      color: theme.colors.primary.contrast,
    },
  },
}));
