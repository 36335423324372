import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { StyledBox, StyledUploadFileIcon } from '@pages/Home/components/HomeNotifications/styled';
import { UploadFileModalId } from '@shared/UploadFileModal';

import { Fab, Typography } from '@mui/material';

const UploadNotifications = () => {
  const { t } = useTranslation();

  const handleOpenUploadModal = () => NiceModal.show(UploadFileModalId);

  return (
    <StyledBox
      borderRadius="5px"
      height="254px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography maxWidth="320px" align="center" fontWeight={400} letterSpacing="0.15px" lineHeight={1.5} mb={5}>
        {t('home.uploadFirstInvoice')}
      </Typography>

      <Fab color="secondary" onClick={handleOpenUploadModal}>
        <StyledUploadFileIcon />
      </Fab>
    </StyledBox>
  );
};

export default memo(UploadNotifications);
