import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSubscribeNowButton } from '@pages/Settings/components/BillingTab/components/styled';
import SubscriptionPill from '@pages/Settings/components/BillingTab/components/SubscriptionPill';
import { getDifferenceDate } from '@pages/Settings/utils/getBillingDates';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Alert, Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

const FreeView: FC<{ daysLeft: number; handleRedirect: () => void }> = ({ daysLeft, handleRedirect }) => {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <SubscriptionPill
        icon={<HourglassBottomIcon />}
        locale={t('settings.trialEndsIn', {
          days: `${Math.abs(daysLeft)} ${daysLeft > 1 ? `${t('common.days')}` : `${t('common.days').slice(0, -1)}`}`,
        })}
      />

      <Box mt="18px" mb={3} display="flex" alignItems="center" gap={4}>
        <CalendarTodayIcon color="action" />
        <Box aria-label="free-trial-end-date-section">
          <Typography color={alpha('#000', 0.87)} fontWeight={400}>
            {t('settings.freeTrialEndDateTitle')}
          </Typography>
          <Typography color={alpha('#000', 0.6)} fontWeight={400} fontSize={14} mt="6px">
            {getDifferenceDate(daysLeft)}
          </Typography>
        </Box>
      </Box>
      <Alert variant="standard" severity="info">
        {t('settings.alertMessage')}
      </Alert>

      <StyledSubscribeNowButton fullWidth disableRipple color="primary" onClick={handleRedirect}>
        {t('settings.subscribeNow')}
      </StyledSubscribeNowButton>
    </Box>
  );
};

export default memo(FreeView);
