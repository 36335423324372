import { FC, Fragment, memo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { useBreakpoints } from '@hooks/useBreakpoints';
import InvoiceCardDetails from '@pages/Tasks/components/InvoiceCardDetails';
import InvoiceCardEditDetails from '@pages/Tasks/components/InvoiceCardEditDetails';
import { useTasksContext } from '@pages/Tasks/context';
import { DocumentView, SortBy } from '@pages/Tasks/enums';
import { findEditInvoice, findInvoiceIssues, groupInvoicesByDate } from '@pages/Tasks/utils';
import { format } from 'date-fns';

import { Box, Typography } from '@mui/material';

const CardsList: FC<{ invoices: InvoiceRead[]; documentView: DocumentView }> = ({ invoices, documentView }) => {
  const { handleRedirectToInvoice, sortBy, handleCheckbox, getCheckboxState, view } = useTasksContext();

  const { t } = useTranslation();
  const { downSm, downMd } = useBreakpoints();

  const { control } = useFormContext();

  const { fields: formFields } = useFieldArray({
    control,
    name: 'data',
    keyName: 'fieldId',
  });

  const formatDateGroup = (dateKey: string): { month: string; year: string } => {
    if (dateKey === 'null') {
      return { month: t('tasks.missingSupportDate'), year: '' };
    }

    try {
      const [year, month] = dateKey.split('-');
      const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1);
      return {
        month: format(date, 'MMMM'),
        year: format(date, 'yyyy'),
      };
    } catch {
      return { month: t('tasks.missingSupportDate'), year: '' };
    }
  };

  const groupInvoicesByFirstLetter = (data: InvoiceRead[]) => {
    const groups: { [key: string]: InvoiceRead[] } = {};

    data.forEach(inv => {
      const key = inv.vendor_name ? inv.vendor_name.charAt(0).toUpperCase() : 'null';

      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(inv);
    });

    return groups;
  };

  const renderGroupedByName = () => {
    const groups = groupInvoicesByFirstLetter(invoices);

    return Object.entries(groups).map(([letter, groupInvoices], index) => (
      <Fragment key={letter}>
        <Box gridColumn="1 / -1" mt={index === 0 ? 1 : 4} mb={downMd ? 0 : 2}>
          <Typography fontSize={16} fontWeight={600} color="text.primary">
            {letter === 'null' ? t('tasks.missingVendorName') : letter}
          </Typography>
        </Box>
        {groupInvoices.map(inv => (
          <Box mr={!downSm && !downMd ? 4 : 0} my={downMd ? 2 : 0}>
            <InvoiceCardDetails
              key={inv.id}
              invoice={inv}
              actionRequired={findInvoiceIssues(inv)}
              handleViewInvoiceDetails={handleRedirectToInvoice}
              handleCheckbox={handleCheckbox}
              getCheckboxState={getCheckboxState}
              view={view}
            />
          </Box>
        ))}
      </Fragment>
    ));
  };

  const renderGroupedByDate = () => {
    const groups = groupInvoicesByDate(invoices);

    return Object.entries(groups).map(([dateKey, groupInvoices], index) => (
      <Fragment key={dateKey}>
        <Box gridColumn="1 / -1" mt={index === 0 ? 1 : 4} mb={!downMd ? 2 : 0}>
          <Typography fontSize={16} fontWeight={400} color="text.primary">
            <Box component="span" fontWeight={500}>
              {formatDateGroup(dateKey).month}
            </Box>{' '}
            {formatDateGroup(dateKey).year}
          </Typography>
        </Box>
        {groupInvoices.map(inv => (
          <Box mr={!downSm && !downMd ? 4 : 0} my={downMd ? 2 : 0}>
            <InvoiceCardDetails
              key={inv.id}
              invoice={inv}
              actionRequired={findInvoiceIssues(inv)}
              handleViewInvoiceDetails={handleRedirectToInvoice}
              handleCheckbox={handleCheckbox}
              getCheckboxState={getCheckboxState}
              view={view}
            />
          </Box>
        ))}
      </Fragment>
    ));
  };

  const renderEditModeCards = () => (
    <>
      {formFields.map((inv, index) => (
        <Fragment key={inv.fieldId}>
          <InvoiceCardEditDetails invoice={findEditInvoice(inv, invoices)} idx={index} view={view} />
        </Fragment>
      ))}
    </>
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: documentView === DocumentView.EDITOR ? 'repeat(auto-fill, 502px)' : '1fr',
          sm: documentView === DocumentView.EDITOR ? 'repeat(auto-fill, 502px)' : '1fr',
          md: documentView === DocumentView.EDITOR ? 'repeat(auto-fill, 502px)' : '1fr 1fr',
          lg: documentView === DocumentView.EDITOR ? 'repeat(auto-fill, 502px)' : '1fr 1fr 1fr',
          xl: documentView === DocumentView.EDITOR ? 'repeat(auto-fill, 502px)' : 'repeat(4, 1fr)',
        }}
        px={{
          xs: 0,
          sm: '60px',
          md: 0,
        }}
        gap={sortBy === SortBy.NONE ? '38px' : 0}
        mb={{
          xs: formFields.length > 0 && documentView === DocumentView.DETAILS ? '240px' : 0,
          md: formFields.length > 0 && documentView === DocumentView.DETAILS ? '140px' : 0,
        }}
        mx={{
          xs: 0,
          md: 'auto',
          lg: 0,
        }}
      >
        {/* Sort by titles except default */}
        {invoices.length > 0 && sortBy === SortBy.DESC && documentView === DocumentView.DETAILS && (
          <Box gridColumn="1 / -1" mb={3}>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14} fontWeight={400}>
                {t('tasks.sortAlphabetically')}
              </Typography>
            </Box>
          </Box>
        )}
        {invoices.length > 0 && sortBy === SortBy.DATE && documentView === DocumentView.DETAILS && (
          <Box gridColumn="1 / -1" mb={3}>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14} fontWeight={400}>
                {t('tasks.sortByDate')}
              </Typography>
            </Box>
          </Box>
        )}
        {/* SORT BY NAME */}
        {documentView === DocumentView.DETAILS && sortBy === SortBy.DESC && renderGroupedByName()}
        {/* SORT BY DATE */}
        {documentView === DocumentView.DETAILS && sortBy === SortBy.DATE && renderGroupedByDate()}
        {/* SORT BY DEFAULT */}
        {documentView === DocumentView.DETAILS &&
          sortBy === SortBy.NONE &&
          invoices.map(inv => (
            <InvoiceCardDetails
              key={inv.id}
              invoice={inv}
              actionRequired={findInvoiceIssues(inv)}
              handleViewInvoiceDetails={handleRedirectToInvoice}
              handleCheckbox={handleCheckbox}
              getCheckboxState={getCheckboxState}
              view={view}
            />
          ))}
        {/* EDIT MODE CARDS */}
        {documentView === DocumentView.EDITOR && renderEditModeCards()}
      </Box>
    </Box>
  );
};

export default memo(CardsList);
