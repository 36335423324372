import { useSearchParams } from 'react-router-dom';
import { formatDate } from '@utils/formatTime';

export const useFiltersUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceStartDate = searchParams.get('service_start_date') || undefined;
  const serviceEndDate = searchParams.get('service_end_date') || undefined;
  const supportCategory = searchParams.get('support_category') || undefined;
  const searchField = searchParams.get('search') || undefined;

  return {
    serviceStartDate,
    serviceEndDate,
    supportCategory,
    searchField,
    setStartDate: (value?: Date | null) => {
      value ? searchParams.set('service_start_date', formatDate(value)) : searchParams.delete('service_start_date');
      setSearchParams(searchParams);
    },
    setEndDate: (value?: Date | null) => {
      value ? searchParams.set('service_end_date', formatDate(value)) : searchParams.delete('service_end_date');
      setSearchParams(searchParams);
    },
    setSupportCategory: (value?: string) => {
      value ? searchParams.set('support_category', String(value)) : searchParams.delete('support_category');
      setSearchParams(searchParams);
    },
    setSearchField: (value?: string) => {
      value ? searchParams.set('search', String(value)) : searchParams.delete('search');
      setSearchParams(searchParams);
    },
    resetFilters: () => {
      searchParams.delete('search');
      searchParams.delete('support_category');
      searchParams.delete('service_end_date');
      searchParams.delete('service_start_date');
      setSearchParams(searchParams);
    },
  };
};
