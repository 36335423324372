import { useMemo } from 'react';
import { ApiInvoicesListApiArg, useApiInvoicesListQuery } from '@api/api';

interface UseInvoicesData extends ApiInvoicesListApiArg {
  refetchOnFocus?: boolean;
}

export const useInvoicesData = ({ refetchOnFocus, ...options }: UseInvoicesData = {}) => {
  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    refetch: refetchInvoices,
  } = useApiInvoicesListQuery(options, {
    refetchOnFocus,
  });

  const unclaimedInvoices = useMemo(
    () => invoicesData?.results.filter(inv => !inv.is_claimed && !inv.hide_is_claimed) || [],
    [invoicesData?.results],
  );

  const unpaidInvoices = useMemo(
    () => invoicesData?.results.filter(inv => !inv.is_paid && !inv.hide_is_paid) || [],
    [invoicesData?.results],
  );

  return {
    invoicesData,
    isLoadingInvoices,
    unclaimedInvoices,
    unpaidInvoices,
    refetchInvoices,
  };
};
