import { memo } from 'react';

import Stack from '@mui/material/Stack';

import Greeting from './Greeting';
import Tasks from './Tasks';

const HomeHeader = () => (
  <Stack gap={5} textAlign="center">
    <Greeting />
    <Tasks />
  </Stack>
);

export default memo(HomeHeader);
