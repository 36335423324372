import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { APP_BAR_HEIGHT } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { useInvoicesData } from '@hooks/api/useInvoicesData';
import { useUser } from '@hooks/api/useUser';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import { useSessionId } from '@hooks/useSessionId';
import HomeHeader from '@pages/Home/components/HomeHeader';
import HomeNotifications from '@pages/Home/components/HomeNotifications';
import { HomeContext } from '@pages/Home/context';
import { useClearingList } from '@pages/Home/hooks/useClearingList';

import Stack from '@mui/material/Stack';

import { StyledBox } from './styled';
import { NotificationModalId } from '@/shared/NotificationModal/NotificationModal';

const Home: FC = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading: isLoadingUser } = useUser();
  const { sessionId, removeSessionId } = useSessionId();
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const { isClearing, onClearList } = useClearingList(selectedInvoices);
  const { page = 0, setPage, limit } = usePageFilterUrlParams(5);
  const { invoicesData, isLoadingInvoices } = useInvoicesData({
    order: 'desc',
    orderBy: 'created_at',
    isHidden: false,
    limit,
    offset: page * limit,
    refetchOnFocus: true,
  });

  const {
    unpaidInvoices,
    unclaimedInvoices,
    isLoadingInvoices: isLoadingAllInvoices,
  } = useInvoicesData({
    refetchOnFocus: true,
  });

  useEffect(() => {
    if (userData && sessionId) {
      NiceModal.show(NotificationModalId, {
        title: <Trans i18nKey="dashboard.notifications.subscribe.success.modalTitle" />,
      });
      removeSessionId();
    }
  }, [sessionId, removeSessionId, t, userData]);

  const invoicesToClaim = unclaimedInvoices.length;
  const invoicesToPay = unpaidInvoices.length;
  const isAllSelected = selectedInvoices.length === invoicesData?.results.length;
  const count = Math.ceil((invoicesData?.count || 0) / limit);
  const invoices = useMemo(() => invoicesData?.results || [], [invoicesData]);

  const onSelectAll = useCallback(() => {
    setSelectedInvoices(current => {
      if (current.length === invoices.length) {
        return [];
      }

      return invoices.map(i => i.id);
    });
  }, [invoices]);

  const onToggleItem = useCallback((id: number) => {
    setSelectedInvoices(current =>
      current.includes(id) ? current.filter(selectedId => selectedId !== id) : [...current, id],
    );
  }, []);

  const onClear = useCallback(async () => {
    await onClearList();
    setSelectedInvoices([]);
  }, [onClearList]);

  const onChangePage = useCallback(
    (p: number) => {
      setPage(p);
      setSelectedInvoices([]);
    },
    [setPage],
  );

  const value = useMemo(
    () => ({
      invoicesToClaim,
      invoicesToPay,
      invoices,
      isLoadingInvoices: isLoadingInvoices || isLoadingAllInvoices,
      onToggleItem,
      onSelectAll,
      isAllSelected,
      selectedInvoices,
      onClearList: onClear,
      isClearing,

      count,
      page,
      onChangePage,
    }),
    [
      invoicesToClaim,
      invoicesToPay,
      invoices,
      isLoadingInvoices,
      isLoadingAllInvoices,
      onToggleItem,
      onSelectAll,
      isAllSelected,
      selectedInvoices,
      onClear,
      isClearing,
      count,
      page,
      onChangePage,
    ],
  );

  if (isLoadingUser) return null;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.home')}</title>
      </Helmet>
      <HomeContext.Provider value={value}>
        <StyledBox py={7.5} width="100%" minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`}>
          <Stack gap="60px" px={3} maxWidth="712px" m="auto">
            <HomeHeader />
            <HomeNotifications />
          </Stack>
        </StyledBox>
      </HomeContext.Provider>
    </>
  );
};

export default memo(Home);
