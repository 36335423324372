import { FC, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import DeleteFlow from '@pages/Settings/components/AccountTab/components/DeleteFlow';
import ManageAccount from '@pages/Settings/components/AccountTab/components/ManageAccount';
import Participants from '@pages/Settings/components/AccountTab/components/Participants';
import PersonalDetails from '@pages/Settings/components/AccountTab/components/PersonalDetails';
import { StyledAccountTypography } from '@pages/Settings/components/AccountTab/styled';
import { useSettingsContext } from '@pages/Settings/context';
import { useUpdatePersonalDetails } from '@pages/Settings/hooks/useUpdatePersonalDetails';

import { Box, LinearProgress } from '@mui/material';

const AccountTab: FC = () => {
  const { t } = useTranslation();
  const { userData, userName, isLoading, isDeleteFlow } = useSettingsContext();

  const { personalDetailsForm, handleSubmitPersonalDetails } = useUpdatePersonalDetails(userData);

  if (isLoading) {
    return (
      <Box width="100%">
        <LinearProgress />
      </Box>
    );
  }

  if (isDeleteFlow) {
    return <DeleteFlow />;
  }

  return (
    <Box
      height="100%"
      width="100%"
      pt={{
        xs: 5,
        md: 7.5,
      }}
      px={{
        xs: 2,
        sm: 2.5,
        md: 0,
        lg: 0,
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxWidth={{
        xs: '100%',
        md: '550px',
        lg: '505px',
      }}
      pb="100px"
    >
      <Trans
        i18nKey={t('settings.userAccount')}
        values={{
          userName,
        }}
        components={{
          header5: <StyledAccountTypography fontSize={24} fontFamily="WFVisualSans" />,
        }}
      />

      <Box mt={4} display="flex" flexDirection="column" gap={4}>
        <FormProvider {...personalDetailsForm}>
          <form onSubmit={personalDetailsForm.handleSubmit(handleSubmitPersonalDetails)}>
            <PersonalDetails />
          </form>
        </FormProvider>
        <Participants />
        <ManageAccount />
      </Box>
    </Box>
  );
};

export default memo(AccountTab);
