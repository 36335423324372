import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)<BoxProps>(() => ({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(102, 102, 102, 0.2) 100%)',
}));

export const StyledUploadFileIcon = styled(UploadFileIcon)(({ theme }) => ({
  color: theme.colors.contrast,
}));
