import { SetStateAction, useCallback, useMemo } from 'react';
import { ApiCategoriesListApiResponse } from '@api/api';
import { getCategoriesOptions } from '@pages/InvoiceDetails/utils';

interface HookArguments {
  categoriesOptions?: ApiCategoriesListApiResponse;
  supportCategory?: string;
  setCategory: ((value: SetStateAction<string | undefined>) => void) | ((value?: string) => void);
}

export const useCategorySelect = (args: HookArguments) => {
  const { categoriesOptions, supportCategory, setCategory } = args;

  const options = useMemo(() => getCategoriesOptions(categoriesOptions), [categoriesOptions]);
  const selectedIds = supportCategory ? supportCategory.split(',').map(Number) : [];

  const getDisplayText = useCallback(
    (selected: number[]) =>
      options
        .filter(option => selected.includes(option.id))
        .map(option => option.label)
        .join(', '),
    [options],
  );

  const handleCategoryChange = useCallback(
    (event: any) => {
      const {
        target: { value },
      } = event;
      setCategory(typeof value === 'string' ? value : value.join(','));
    },
    [setCategory],
  );

  return {
    selectedIds,
    getDisplayText,
    handleCategoryChange,
    options,
  };
};
