import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useApiInvoicesListQuery } from '@api/api';
import { SUCCESS } from '@constants/auth';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useFiltersUrlParams } from '@hooks/useFiltersUrlParams';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import { useSubscription } from '@hooks/useSubscription';
import CalendarView from '@pages/Invoices/components/CalendarView';
import SpeedDialButton from '@pages/Invoices/components/CalendarView/SpeedDialButton';
import ControlsBar from '@pages/Invoices/components/ControlsBar';
import Filters from '@pages/Invoices/components/TableView/Filters';
import InvoicesTable from '@pages/Invoices/components/TableView/InvoicesTable';
import {
  SEARCH_DEBOUNCE_TIMEOUT,
  UPLOAD_INVOICE_REFETCH_TIMEOUT,
  UPLOAD_SINGLE_REFETCH_TIMEOUT,
} from '@pages/Invoices/constants';
import { useInvoicesContext } from '@pages/Invoices/context';
import { VIEW } from '@pages/Invoices/enums';
import PreviewInvoice from '@pages/Invoices/modals/PreviewInvoice';
import { StyledFiltersIconButton, StyledSearchTextField } from '@pages/Invoices/styled';
import { RootState } from '@store/store';
import { resetUploadInvoice } from '@store/uploadSlice';
import { getEndOfMonth, getStartOfMonth } from '@utils/formatTime';
import { closeSnackbar, useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, InputAdornment, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GridPaginationModel } from '@mui/x-data-grid';

const action = (snackbarId: string | number) => (
  <>
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      sx={{ '& svg': { color: '#FFF' } }}
    >
      <CloseIcon />
    </IconButton>
  </>
);

const Invoices: FC = () => {
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { serviceStartDate, serviceEndDate, supportCategory, searchField, setSearchField } = useFiltersUrlParams();
  const { t } = useTranslation();
  const { isLoadingSubscription } = useSubscription();
  const {
    view,
    handleChangeView,
    selectedMonth,
    isLoadingUserData,
    selectedParticipants,
    setSelectedMonth,
    handlePickDate,
    isLoadingCategoryOptions,
    showFilters,
    setShowFilters,
  } = useInvoicesContext();
  const { downSm, downMd } = useBreakpoints();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const dispatch = useDispatch();
  const uploadState = useSelector((state: RootState) => state.upload);
  const snackbar = useSnackbar();
  const [searchTerm] = useDebounce(searchField, SEARCH_DEBOUNCE_TIMEOUT);

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
    refetch,
  } = useApiInvoicesListQuery({
    ...(view === VIEW.TABLE && {
      limit,
      offset: page * limit,
      ...(serviceStartDate && { serviceStartDate }),
      ...(serviceEndDate && { serviceEndDate }),
      ...(searchField && { search: searchTerm }),
      ...(supportCategory && { categoryIds: supportCategory }),
    }),
    ...(view === VIEW.CALENDAR && {
      orderBy: 'created_at',
      order: 'asc',
    }),
    ...(view === VIEW.CALENDAR && {
      serviceStartDate: getStartOfMonth(selectedMonth),
      serviceEndDate: getEndOfMonth(selectedMonth),
      serviceMonth: selectedMonth,
    }),
    ...(view === VIEW.CALENDAR &&
      selectedParticipants.length > 0 && {
        participants: selectedParticipants
          .map(participant => `${participant.first_name} ${participant.last_name}`)
          .join(','),
      }),
  });

  const isLoading = [
    isLoadingInvoices,
    isFetching,
    isLoadingSubscription,
    isLoadingUserData,
    isLoadingCategoryOptions,
  ].some(Boolean);

  useEffect(() => {
    let refetchIntervalId: NodeJS.Timeout;
    let cleanupTimeoutId: NodeJS.Timeout;
    let refetchCount = 0;
    let initialShow = true;

    if (uploadState.refetchInvoices) {
      if (initialShow) {
        snackbar.enqueueSnackbar(t('dashboard.fileUploadedInvoicesPageSnackbarText'), {
          variant: SUCCESS,
          action,
          persist: true,
          preventDuplicate: true,
        });
        initialShow = false;
      }
      refetch();
      // eslint-disable-next-line no-plusplus
      refetchCount++;

      refetchIntervalId = setInterval(() => {
        if (refetchCount < UPLOAD_INVOICE_REFETCH_TIMEOUT / UPLOAD_SINGLE_REFETCH_TIMEOUT + 1) {
          refetch();
          // eslint-disable-next-line no-plusplus
          refetchCount++;
        } else {
          clearInterval(refetchIntervalId);
        }
      }, UPLOAD_SINGLE_REFETCH_TIMEOUT);

      cleanupTimeoutId = setTimeout(() => {
        clearInterval(refetchIntervalId);
        dispatch(resetUploadInvoice());
      }, UPLOAD_INVOICE_REFETCH_TIMEOUT);
    }

    return () => {
      if (refetchIntervalId) {
        clearInterval(refetchIntervalId);
      }
      if (cleanupTimeoutId) {
        clearTimeout(cleanupTimeoutId);
      }
    };
  }, [uploadState.refetchInvoices, dispatch, refetch, snackbar, t]);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoicesReceipts')}</title>
      </Helmet>

      <ControlsBar view={view} onChange={handleChangeView} />
      <Box aria-label="invoices page" display="flex" flexDirection="column" height="100%">
        <Box height="100%">
          {view === VIEW.TABLE && (
            <Box
              p={{
                xs: '30px 16px 65px',
                sm: '30px 20px 60px',
                md: '40px 30px 60px',
                lg: '40px 80px 60px',
              }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <Typography fontFamily="WFVisualSans" fontSize={20} flexGrow={1} noWrap width="100%">
                  {t('dashboard.invoiceAndReceipts')}
                </Typography>
                {!downMd && (
                  <Box display="flex" gap="12px" width="100%" alignItems="center" justifyContent="end">
                    <FormControl sx={{ width: '260px' }} variant="outlined">
                      <StyledSearchTextField
                        name="search"
                        placeholder={t('tableFilters.searchLabel')}
                        value={searchField || ''}
                        inputProps={{ maxLength: 50 }}
                        onChange={ev => setSearchField(ev.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon color={searchField ? 'primary' : 'inherit'} />
                            </InputAdornment>
                          ),
                          endAdornment: searchField && (
                            <InputAdornment position="end">
                              <IconButton disableRipple onClick={() => setSearchField('')}>
                                <CloseIcon color="primary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    </FormControl>

                    <StyledFiltersIconButton
                      disableRipple
                      onClick={() => setShowFilters(!showFilters)}
                      isExpanded={showFilters}
                    >
                      <FilterAltIcon color="primary" />
                    </StyledFiltersIconButton>
                  </Box>
                )}
              </Box>
              <Box>
                <Filters />
              </Box>
              <InvoicesTable
                invoices={invoicesData}
                isLoading={isLoading}
                handlePageChange={handlePaginationModelChange}
                paginationModel={{ page, pageSize: limit }}
              />
            </Box>
          )}
          {view === VIEW.CALENDAR && <CalendarView invoicesData={invoicesData} isLoading={isLoading} />}
        </Box>
        <PreviewInvoice />
        {view === VIEW.CALENDAR && downSm && (
          <SpeedDialButton handleSetMonth={setSelectedMonth} handlePickDate={handlePickDate} />
        )}
      </Box>
    </>
  );
};

export default memo(Invoices);
