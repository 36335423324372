import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useUser } from '@hooks/api/useUser';
import { RootState } from '@store/store';
import { tokenStorage } from '@utils/tokenStorage';

import { Box, Typography } from '@mui/material';
import LogoIconMedium from '@components/LogoIconMedium';

const DeletedPage: FC = () => {
  const { t } = useTranslation();
  const { userName, isLoading } = useUser();
  const deleteState = useSelector((state: RootState) => state.delete);
  const navigate = useNavigate();

  useEffect(() => {
    tokenStorage.cleanup();
    if (!deleteState.isDeleted) {
      return navigate(ROUTING.ROOT);
    }
  }, [deleteState.isDeleted, navigate]);

  if (isLoading) return null;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.deleted')}</title>
      </Helmet>
      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box mt="133px" maxWidth={683} display="flex" flexDirection="column" textAlign="center" alignItems="center">
          <LogoIconMedium aria-label="company logo" />
          <Typography fontFamily="WFVisualSans" fontSize={20} mt={3}>
            {t('deleteFlow.deletedPage.title')}
          </Typography>
          <Typography fontWeight={400} mt={3}>
            {t('deleteFlow.deletedPage.subtitle', { firstName: userName })}
          </Typography>
          <Typography fontWeight={400} mt={4}>
            {t('deleteFlow.deletedPage.text')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default memo(DeletedPage);
