import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBox } from '@pages/Home/components/HomeNotifications/styled';

import { Typography } from '@mui/material';

const NoInvoices = () => {
  const { t } = useTranslation();

  return (
    <StyledBox borderRadius="10px" display="flex" alignItems="center" justifyContent="center" height="320px">
      <Typography variant="body1" fontWeight={400}>
        {t('home.noNewInvoices')}
      </Typography>
    </StyledBox>
  );
};

export default memo(NoInvoices);
