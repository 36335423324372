import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { TaskView } from '@pages/Tasks/enums';
import { StyledEmptyListBox } from '@pages/Tasks/styled';

import { Typography } from '@mui/material';

const EmptyList: FC<{ view: TaskView }> = ({ view }) => {
  const { t } = useTranslation();
  const { downSm } = useBreakpoints();

  return (
    <StyledEmptyListBox
      display="flex"
      flexDirection="column"
      textAlign="center"
      pt="127px"
      px={downSm ? '10px' : '30px'}
    >
      <Typography fontFamily="WFVisualSans" fontSize={20} mb="26px">
        <Trans
          i18nKey={t('tasks.emptyList.title')}
          values={{ tabLocale: view === TaskView.CLAIM ? t('tasks.claim.title') : t('tasks.pay.title') }}
        />
      </Typography>

      <Typography fontSize={14} fontWeight={400}>
        <Trans
          i18nKey={t('tasks.emptyList.subtitle_1')}
          values={{ actionType: view === TaskView.CLAIM ? t('tasks.state.claimed') : t('tasks.state.paid') }}
        />
      </Typography>
      <Typography fontSize={14} fontWeight={400}>
        {t('tasks.emptyList.subtitle_2')}
      </Typography>
    </StyledEmptyListBox>
  );
};

export default EmptyList;
