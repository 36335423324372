/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSubscription } from '@hooks/useSubscription';
import { StepProps } from '@pages/Settings/components/AccountTab/components/DeleteFlow/types';
import { StyledList } from '@pages/Settings/components/AccountTab/styled';
import { SettingsTab } from '@pages/Settings/constants/enums';
import { useSettingsContext } from '@pages/Settings/context';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Alert, AlertTitle, Box, Link, ListItem, Typography } from '@mui/material';

const listItems = [
  'deleteFlow.stepOneFirstBullet',
  'deleteFlow.stepOneSecondBullet',
  'deleteFlow.stepOneThirdBullet',
  'deleteFlow.stepOneFourthBullet',
];

const NotifyStep: FC<StepProps> = ({ handleBack }) => {
  const { t } = useTranslation();
  const { isTrial } = useSubscription();
  const { handleChange } = useSettingsContext();

  return (
    <Box mt={3}>
      <Typography fontSize={20} fontFamily="WFVisualSans" mb={3}>
        {t('deleteFlow.stepOneTitle')}
      </Typography>
      {!isTrial && (
        <Alert severity="warning">
          <AlertTitle>{t('deleteFlow.stepOneAlertTitle')}</AlertTitle>
          <Typography fontWeight={400}>
            <Trans
              i18nKey={t('deleteFlow.stepOneAlertText')}
              components={{
                btn: (
                  <span>
                    <Link
                      component="button"
                      onClick={_ev => {
                        handleChange(_ev, SettingsTab.Billing);
                        handleBack();
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      {t('deleteFlow.alertBtnBack')}
                    </Link>
                  </span>
                ),
              }}
            />
          </Typography>
        </Alert>
      )}

      <Typography fontWeight={600} mt={4} mb={1}>
        {t('deleteFlow.stepOneSubtitle')}
      </Typography>
      <StyledList>
        {listItems.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index}>
            <FiberManualRecordIcon
              sx={{
                fontSize: 6,
                mx: 1,
              }}
            />
            <Typography fontWeight={400} ml="2px">
              {t(item)}
            </Typography>
          </ListItem>
        ))}
      </StyledList>
    </Box>
  );
};

export default memo(NotifyStep);
