import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledChip } from '@pages/Home/components/HomeNotifications/NotificationsList/styled';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

interface NotificationChipProps {
  viewed: boolean;
}

const NotificationChip: FC<NotificationChipProps> = ({ viewed }) => {
  const { t } = useTranslation();

  return (
    <StyledChip
      viewed={viewed}
      label={
        <>
          {!viewed && <AutoAwesomeIcon />} {viewed ? t('home.viewed') : t('home.new')}
        </>
      }
    />
  );
};

export default NotificationChip;
