import { BREAKPOINTS, DOWN, UP } from '@constants/common';
import { useResponsive } from '@hooks/useResponsive';

export const useBreakpoints = () => {
  const upSm = useResponsive(UP, BREAKPOINTS.SM);
  const downSm = useResponsive(DOWN, BREAKPOINTS.SM);
  const upMd = useResponsive(UP, BREAKPOINTS.MD);
  const downMd = useResponsive(DOWN, BREAKPOINTS.MD);
  const upLg = useResponsive(UP, BREAKPOINTS.LG);
  const downLg = useResponsive(DOWN, BREAKPOINTS.LG);
  const upXl = useResponsive(UP, BREAKPOINTS.XL);
  const downXl = useResponsive(DOWN, BREAKPOINTS.XL);

  return {
    upSm,
    downSm,
    upMd,
    downMd,
    upLg,
    downLg,
    upXl,
    downXl,
  };
};
