import { FC, PropsWithChildren } from 'react';
import { StyledTransitionBox } from '@providers/layouts/AppLayout/styled';

import Box from '@mui/material/Box';

const Main: FC<PropsWithChildren> = ({ children }) => (
  <StyledTransitionBox
    aria-label="application current view"
    display="flex"
    flexDirection="column"
    width="100%"
    minHeight={1}
    flexGrow={1}
  >
    <Box height="100%">{children}</Box>
  </StyledTransitionBox>
);

export default Main;
