import { createContext, useContext } from 'react';
import { InvoiceRead } from '@api/api';

const INITIAL_FUNC = () => {};

export const HomeContext = createContext<{
  invoicesToClaim: number;
  invoicesToPay: number;
  invoices: InvoiceRead[];
  isLoadingInvoices: boolean;
  onToggleItem: (id: number) => void;
  onSelectAll: () => void;
  onClearList: () => void;
  isClearing: boolean;
  selectedInvoices: number[];
  isAllSelected: boolean;

  page: number;
  onChangePage: (page: number) => void;
  count?: number;
}>({
  invoicesToClaim: 0,
  invoicesToPay: 0,
  invoices: [],
  isLoadingInvoices: false,
  onToggleItem: INITIAL_FUNC,
  onSelectAll: INITIAL_FUNC,
  onClearList: INITIAL_FUNC,
  selectedInvoices: [],
  isAllSelected: false,
  isClearing: false,

  page: 0,
  onChangePage: INITIAL_FUNC,
});

export const useHomeContext = () => {
  const context = useContext(HomeContext);
  if (!context) throw new Error('useInvoicesContext must be inside InvoicesProvider');
  return context;
};
