import { useEffect } from 'react';
import { useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';

export const useViewInvoice = ({ id, skip }: { id?: number; skip?: boolean }) => {
  const [invoicePartialUpdateMutation] = useApiInvoicesUpdatePartialUpdateMutation();

  useEffect(() => {
    if (id && !skip) {
      invoicePartialUpdateMutation({
        id,
        patchedInvoiceUpdateRequest: {
          is_viewed: true,
        },
      });
    }
  }, [invoicePartialUpdateMutation, id, skip]);
};
