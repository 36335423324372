import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTING } from '@constants/routing';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { StyledTasksBox, StyledTasksDivider, StyledTasksLink } from '@pages/Home/components/HomeHeader/styled';
import { useHomeContext } from '@pages/Home/context';
import RouterLink from '@routes/components/RouterLink';

import { Box, CircularProgress, Typography } from '@mui/material';

const Tasks = () => {
  const { t } = useTranslation();
  const { invoicesToPay, invoicesToClaim, isLoadingInvoices } = useHomeContext();
  const { upSm } = useBreakpoints();

  const noTasks = !invoicesToPay && !invoicesToClaim;

  return (
    <StyledTasksBox
      minHeight="88px"
      display="flex"
      gap={4}
      justifyContent="space-between"
      borderRadius="100px"
      px="30px"
      py={2}
    >
      <Box display="flex" alignItems="center" justifyContent={upSm ? 'space-between' : 'flex-end'} flexGrow={1}>
        {upSm && (
          <Typography component="p" fontWeight={400}>
            {t('home.tasksToComplete')}
          </Typography>
        )}

        {isLoadingInvoices ? (
          <CircularProgress />
        ) : (
          <Typography
            display="flex"
            lineHeight={1.43}
            flexDirection="column"
            gap="2px"
            fontFamily="WFVisualSans"
            fontSize={24}
          >
            {invoicesToClaim}
            <Typography variant="body2" lineHeight={1.33} component="span">
              {t('home.toClaim')}
            </Typography>
          </Typography>
        )}
      </Box>

      <StyledTasksDivider orientation="vertical" />

      <Box display="flex" alignItems="center" justifyContent="space-between" gap={4} flexGrow={1}>
        {isLoadingInvoices ? (
          <CircularProgress />
        ) : (
          <Typography
            display="flex"
            lineHeight={1.43}
            flexDirection="column"
            gap="2px"
            fontFamily="WFVisualSans"
            fontSize={24}
          >
            {invoicesToPay}
            <Typography variant="body2" lineHeight={1.33} component="span">
              {t('home.toPay')}
            </Typography>
          </Typography>
        )}

        {noTasks ? (
          upSm && (
            <Typography fontSize={16} variant="body2" maxWidth="143px" align="center" color="#E0E0E0" component="span">
              {t('home.noOutstandingTasks')}
            </Typography>
          )
        ) : (
          <StyledTasksLink component={RouterLink} to={`/${ROUTING.TASKS}`} fontWeight={400}>
            {t('home.goToTasks')}
          </StyledTasksLink>
        )}
      </Box>
    </StyledTasksBox>
  );
};

export default memo(Tasks);
