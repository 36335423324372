import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFiltersUrlParams } from '@hooks/useFiltersUrlParams';

import { Box, Typography } from '@mui/material';

const NoInvoicesMessage: FC = () => {
  const { t } = useTranslation();
  const { searchField, serviceStartDate, serviceEndDate, supportCategory } = useFiltersUrlParams();

  const isFiltersApplied = [searchField, serviceStartDate, serviceEndDate, supportCategory].some(Boolean);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mx="20px" mt="-40px" py="10px" bgcolor="#F6F6F7">
      <Typography fontSize={14} fontWeight={400} fontFamily="WFVisualSans">
        {t(isFiltersApplied ? 'dashboard.noResultsFound' : 'dashboard.invoicesMessage')}
      </Typography>
    </Box>
  );
};

export default memo(NoInvoicesMessage);
