/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DeleteState {
  isDeleted: boolean;
  feedback: string | null;
  option: string | null;
  checkboxVal: boolean;
  password: string;
  isError: boolean | null;
}

const initialState: DeleteState = {
  isDeleted: false,
  feedback: null,
  option: null,
  checkboxVal: false,
  password: '',
  isError: null,
};
const deleteSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    deleteSuccess: state => {
      state.isDeleted = true;
    },
    saveFeedback: (state: DeleteState, action: PayloadAction<string>) => {
      state.feedback = action.payload;
    },
    saveOption: (state: DeleteState, action: PayloadAction<string>) => {
      state.option = action.payload;
    },
    toggleCheckboxVal: (state: DeleteState) => {
      state.checkboxVal = !state.checkboxVal;
    },
    setPasswordValid: (state: DeleteState) => {
      state.isError = false;
    },
    setPasswordInvalid: (state: DeleteState) => {
      state.isError = true;
    },
    setPassword: (state: DeleteState, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
  },
});
export const {
  deleteSuccess,
  saveOption,
  saveFeedback,
  toggleCheckboxVal,
  setPasswordValid,
  setPasswordInvalid,
  setPassword,
} = deleteSlice.actions;
export default deleteSlice.reducer;
