import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import { getFullTodayDate } from '@utils/formatTime';

import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

const Greeting = () => {
  const { t } = useTranslation();
  const { userName } = useUser();

  return (
    <Stack gap={2}>
      <Typography component="p" variant="custom" textTransform="uppercase">
        {getFullTodayDate()}
      </Typography>
      <Typography variant="h3" fontSize={32} component="h1">
        {t('home.greeting', { time: 'afternoon' })}, {userName}
      </Typography>
    </Stack>
  );
};

export default memo(Greeting);
