import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsSkeleton from '@pages/Home/components/HomeNotifications/NotificationsList/NotificationsSkeleton';
import {
  StyledButton,
  StyledCheckbox,
  StyledPagination,
  StyledPaginationItem,
  StyledSelectAllLabel,
} from '@pages/Home/components/HomeNotifications/NotificationsList/styled';
import { useHomeContext } from '@pages/Home/context';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import NotificationsItem from './NotificationsItem';

const NotificationsList = () => {
  const { t } = useTranslation();
  const {
    invoices,
    isAllSelected,
    onSelectAll,
    onToggleItem,
    selectedInvoices,
    onClearList,
    isClearing,
    page,
    count,
    onChangePage,
    isLoadingInvoices,
  } = useHomeContext();
  const isAnySelected = !!selectedInvoices.length;

  return (
    <Box>
      <Box display="flex" gap={3} justifyContent="space-between" alignItems="center" mb={2}>
        {invoices.length > 1 && (
          <StyledSelectAllLabel
            checked={isAllSelected}
            onChange={onSelectAll}
            control={<StyledCheckbox />}
            label={t('home.selectAll')}
          />
        )}

        {isAnySelected && (
          <StyledButton loading={isClearing} onClick={onClearList}>
            {t('home.clearFromList')}
          </StyledButton>
        )}
      </Box>

      <Stack mb={2}>
        {isLoadingInvoices && <NotificationsSkeleton />}

        {invoices.map(i => (
          <NotificationsItem
            key={i.id}
            invoice={i}
            onToggleItem={() => onToggleItem(i.id)}
            checked={selectedInvoices.includes(i.id)}
          />
        ))}

        {count && count > 1 && (
          <StyledPagination
            count={count}
            page={page + 1}
            siblingCount={0}
            size="large"
            onChange={(_, v) => onChangePage(v - 1)}
            renderItem={item => <StyledPaginationItem {...item} />}
          />
        )}
      </Stack>

      <Typography variant="overline" textTransform="none" lineHeight={1.66} component="p">
        {t('home.autoClearInfo')}
      </Typography>
    </Box>
  );
};

export default memo(NotificationsList);
