import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import BillingDetailsRow from '@pages/Settings/components/BillingTab/components/BillingDetailsRow';
import SubscriptionPill from '@pages/Settings/components/BillingTab/components/SubscriptionPill';
import { YEARLY_PRICE } from '@pages/Settings/constants';
import { useBillingInfo } from '@pages/Settings/hooks/useBillingInfo';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button, Divider, Typography } from '@mui/material';

const PERIOD = 'dashboard.subscribePage.plans.monthly.period';

const YearlyView: FC<{
  daysLeft: number;
  handleCancel: () => void;
  cardNumber: string;
}> = ({ daysLeft, handleCancel, cardNumber }) => {
  const { t } = useTranslation();
  const details = useBillingInfo({
    period: PERIOD,
    price: YEARLY_PRICE,
    cardNumber,
    daysLeft,
  });

  return (
    <Box width="100%">
      <SubscriptionPill icon={<TaskAltIcon />} locale={t('settings.activeAnnuallySubscription')} />

      <Box mt="37px" mb="55px" display="flex" flexDirection="column" gap={1.5}>
        {details.map(({ icon, detailsTitle, detailsText }, index) => (
          <Box display="flex" key={detailsTitle} flexDirection="column" gap={1}>
            <BillingDetailsRow icon={icon} detailsTitle={detailsTitle} detailsText={detailsText} />
            {details.length - 1 !== index && <Divider />}
          </Box>
        ))}
      </Box>

      <Box width="100%" display="flex" flexDirection="column" gap={5}>
        <Button
          disableRipple
          fullWidth
          onClick={handleCancel}
          color="primary"
          style={{
            height: 42,
            boxShadow: 'none',
          }}
          variant="contained"
          endIcon={<OpenInNewIcon />}
        >
          <Typography>{t('settings.manageBilling')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default memo(YearlyView);
