import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StyledCircularProgress } from '@pages/Login/components/styled';
import { useSettingsContext } from '@pages/Settings/context';
import { setPassword } from '@store/deleteSlice';
import { RootState } from '@store/store';

import { Box } from '@mui/material';
import InputPassword from '@components/InputPassword/InputPassword';

const ConfirmPassword: FC = () => {
  const { t } = useTranslation();
  const deleteState = useSelector((state: RootState) => state.delete);
  const { isError, password } = deleteState;
  const dispatch = useDispatch();
  const { isLoadingCheck } = useSettingsContext();

  return (
    <>
      <InputPassword
        fullWidth
        variant="outlined"
        label={t('common.inputs.password')}
        inputProps={{
          'aria-required': 'true',
          'aria-label': 'password input field',
        }}
        autoComplete="off"
        error={Boolean(isError)}
        helperText={isError && t('deleteFlow.invalidPassword')}
        value={password}
        onChange={ev => {
          dispatch(setPassword(ev.target.value));
        }}
      />
      <Box height={20} pt={1}>
        {isLoadingCheck && <StyledCircularProgress size={16} />}
      </Box>
    </>
  );
};

export default memo(ConfirmPassword);
