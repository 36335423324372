import { useCallback, useState } from 'react';
import { useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';

export const useClearingList = (selectedInvoices: number[]) => {
  const [isClearing, setIsClearing] = useState(false);
  const [invoicePartialUpdateMutation] = useApiInvoicesUpdatePartialUpdateMutation();

  const onClearList = useCallback(async () => {
    setIsClearing(true);

    try {
      await Promise.all(
        selectedInvoices.map(id =>
          invoicePartialUpdateMutation({
            id,
            patchedInvoiceUpdateRequest: {
              // @ts-ignore
              is_hidden: true,
            },
          }),
        ),
      );
    } finally {
      setIsClearing(false);
    }
  }, [invoicePartialUpdateMutation, selectedInvoices]);

  return { isClearing, onClearList };
};
