import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useAuthSigninCreateMutation } from '@api/api';
import { useUser } from '@hooks/api/useUser';
import { useTabs } from '@hooks/useTabs';
import { SettingsTab } from '@pages/Settings/constants/enums';
import { useParticipants } from '@pages/Settings/hooks/useParticipants';

const SettingsContext = createContext<ReturnType<typeof useSettingsContextValue> | null>(null);

const useSettingsContextValue = () => {
  const { data: userData, isLoading: isUserLoading, userName } = useUser();
  const { participantsData, isLoadingParticipantsData } = useParticipants();
  const [isDeleteFlow, setIsDeleteFlow] = useState(false);
  const [loginMutation, { isLoading: loginIsLoading }] = useAuthSigninCreateMutation();
  const { tab, handleChange } = useTabs(SettingsTab.Account, SettingsTab);

  const isLoading = [isUserLoading, isLoadingParticipantsData].some(Boolean);
  const toggleDeleteFlow = useCallback(() => {
    setIsDeleteFlow(!isDeleteFlow);
  }, [isDeleteFlow]);

  return {
    userData,
    isLoading,
    participantsData,
    userName,
    toggleDeleteFlow,
    isDeleteFlow,
    checkMutation: loginMutation,
    isLoadingCheck: loginIsLoading,
    tab,
    handleChange,
  };
};

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('useSettingsContext must be inside SettingsProvider');
  return context;
};

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useSettingsContextValue();
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
