import { memo } from 'react';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material';

const CustomPagination = (props: {
  page: number;
  pageSize: number;
  rowCount: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
}) => {
  const { page, pageSize, rowCount, onPageChange, onPageSizeChange } = props;
  const totalPages = Math.ceil(rowCount / pageSize);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" py="8px">
      <Box display="flex" alignItems="center">
        <Typography fontSize={14} fontWeight={400}>
          Rows per page:
        </Typography>
        <Select
          value={pageSize}
          onChange={e => onPageSizeChange(Number(e.target.value))}
          size="small"
          sx={{
            marginLeft: 1,
            marginRight: 1,
            background: '#fff',
            '& .MuiSelect-select': {
              padding: '5px',
              fontSize: '14px',
              fontWeight: 500,
            },
            '&.MuiInputBase-root::before': {
              display: 'none',
            },
            '&.MuiInputBase-root:hover': {
              backgroundColor: 'transparent !important',
            },
          }}
          variant="standard"
        >
          {[10, 25, 50].map(size => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Typography fontSize={14} fontWeight={400}>
        {page * pageSize + 1}-{Math.min((page + 1) * pageSize, rowCount)} of {rowCount}
      </Typography>
      <Box>
        <IconButton onClick={() => onPageChange(page - 1)} disabled={page === 0}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={() => onPageChange(page + 1)} disabled={page >= totalPages - 1}>
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(CustomPagination);
