import { ChangeEvent, FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '@hooks/api/useUser';
import { StyledRadioGroup, StyledTextField } from '@pages/Settings/components/AccountTab/styled';
import { saveFeedback, saveOption } from '@store/deleteSlice';
import { RootState } from '@store/store';

import { Alert, AlertTitle, Box, FormControl, FormControlLabel, Radio, Typography } from '@mui/material';

const radioItems = [
  'deleteFlow.stepTwoFirstBullet',
  'deleteFlow.stepTwoSecondBullet',
  'deleteFlow.stepTwoThirdBullet',
  'deleteFlow.stepTwoFourthBullet',
  'deleteFlow.stepTwoFifthBullet',
];

const FeedbackStep: FC = () => {
  const { t } = useTranslation();
  const deleteState = useSelector((state: RootState) => state.delete);
  const [option, setOption] = useState<string | null>(deleteState.option);
  const [feedbackVal, setFeedbackVal] = useState(deleteState.feedback || '');
  const { userName } = useUser();
  const dispatch = useDispatch();

  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>) => {
    setOption(event.target.value as string);
    dispatch(saveOption(t(event.target.value as string)));
  };

  const handleFeedback = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFeedbackVal(ev.target.value);
    dispatch(saveFeedback(ev.target.value));
  };

  return (
    <Box mt={3}>
      <Typography fontSize={20} fontFamily="WFVisualSans" mb={3}>
        {t('deleteFlow.stepTwoTitle', { firstName: userName })}
      </Typography>
      <Alert severity="info">
        <AlertTitle>{t('deleteFlow.stepTwoAlertTitle')}</AlertTitle>
        <Typography fontWeight={400}>{t('deleteFlow.stepTwoAlertText')}</Typography>
      </Alert>

      <Typography mt={4} mb="11px">
        {t('deleteFlow.stepTwoSubtitle')}
      </Typography>

      <FormControl>
        <StyledRadioGroup name="controlled-radio-buttons-group" value={option} onChange={handleChangeRadio}>
          {radioItems.map(item => (
            <FormControlLabel
              key={item}
              value={t(item)}
              control={<Radio />}
              label={<Typography fontWeight={400}>{t(item)}</Typography>}
            />
          ))}
        </StyledRadioGroup>
      </FormControl>

      <Typography mt={4} mb={3}>
        {t('deleteFlow.stepTwoFeedbackTitle')}
      </Typography>

      <StyledTextField
        value={feedbackVal}
        aria-label="feedback"
        multiline
        maxRows={4}
        minRows={4}
        variant="outlined"
        placeholder={t('deleteFlow.stepTwoFeedbackPlaceholder')}
        label={t('common.feedback')}
        onChange={handleFeedback}
      />
    </Box>
  );
};

export default memo(FeedbackStep);
