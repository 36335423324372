import { InvoiceEventTypeEnum } from '@api/api';

export const ICON_TYPE: Record<string, InvoiceEventTypeEnum> = {
  custom_event: 'custom_event',
  claim_submitted: 'claim_submitted',
  invoice_paid: 'invoice_paid',
  ndis_funds_received: 'ndis_funds_received',
  invoice_emailed: 'invoice_emailed',
  payment_due_date: 'payment_due_date',
  invoice_uploaded: 'invoice_uploaded',
};

export const INVOICE_TASK_TITLES: Record<InvoiceEventTypeEnum, string> = {
  custom_event: 'dashboard.timeline.customEvent',
  claim_submitted: 'dashboard.timeline.claimSubmitted',
  invoice_paid: 'dashboard.timeline.providerPaid',
  ndis_funds_received: 'dashboard.timeline.fundsReceived',
  invoice_emailed: 'dashboard.timeline.emailedToPlatform',
  payment_due_date: 'dashboard.timeline.paymentDue',
  invoice_uploaded: 'dashboard.timeline.uploadedToPlatform',
};

export const INVOICE_TASK_TIMELINE_TITLES: Record<InvoiceEventTypeEnum, string> = {
  custom_event: 'dashboard.timeline.customEvent',
  claim_submitted: 'dashboard.timeline.claimSubmitted',
  invoice_paid: 'dashboard.timeline.providerPaid',
  ndis_funds_received: 'dashboard.timeline.fundsReceivedTimeline',
  invoice_emailed: 'dashboard.timeline.emailedToPlatform',
  payment_due_date: 'dashboard.timeline.paymentDue',
  invoice_uploaded: 'dashboard.timeline.uploadedToPlatform',
};

export const INVOICE_TASK_DETAILS_TITLES: Record<InvoiceEventTypeEnum, string | undefined> = {
  custom_event: 'dashboard.timeline.details',
  claim_submitted: 'dashboard.timeline.claimIdNumber',
  invoice_paid: 'dashboard.timeline.transactionReference',
  ndis_funds_received: 'dashboard.timeline.transactionReference',
  invoice_emailed: 'dashboard.timeline.sender',
  payment_due_date: undefined,
  invoice_uploaded: 'dashboard.timeline.uploadedBy',
};

export const INVOICE_TASK_IS_EDITABLE: Record<InvoiceEventTypeEnum, boolean> = {
  custom_event: true,
  claim_submitted: true, // true by default but false if it is !is_manual
  invoice_paid: true,
  ndis_funds_received: true,
  invoice_emailed: false,
  payment_due_date: true,
  invoice_uploaded: false,
};

export const INVOICE_POSSIBLE_MANUAL_TASKS: Omit<InvoiceEventTypeEnum, 'invoice_emailed' | 'invoice_uploaded'>[] = [
  'payment_due_date',
  'claim_submitted',
  'ndis_funds_received',
  'invoice_paid',
  'custom_event',
];
