import { FC, memo } from 'react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import LargeSizeTableFilters from '@pages/Invoices/components/TableView/Filters/components/LargeSizeTableFilters';
import SmallSizeTableFilters from '@pages/Invoices/components/TableView/Filters/components/SmallSizeTableFilters';

import { Box } from '@mui/material';

const Filters: FC = () => {
  const { downMd } = useBreakpoints();

  return (
    <Box display="flex" width="100%">
      {downMd && <SmallSizeTableFilters />}
      {!downMd && <LargeSizeTableFilters />}
    </Box>
  );
};

export default memo(Filters);
