import { createContext, FC, PropsWithChildren, SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useApiCategoriesListQuery } from '@api/api';
import { useUser } from '@hooks/api/useUser';
import { useFiltersUrlParams } from '@hooks/useFiltersUrlParams';
import { ICONS, SELECTOR, VIEW } from '@pages/Invoices/enums';
import { ExtendedActiveParticipant } from '@pages/Invoices/types';
import { getCurrentMonthIso, getMonthWithSelectorIso } from '@pages/Invoices/utils';

const InvoicesContext = createContext<ReturnType<typeof useInvoicesContextValue> | null>(null);

const useInvoicesContextValue = () => {
  const { data: userData, isLoading: isLoadingUserData } = useUser();
  const { data: categoriesOptions, isLoading: isLoadingCategoryOptions } = useApiCategoriesListQuery();
  const [view, setView] = useState(VIEW.TABLE);
  const [open, setOpen] = useState(false);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonthIso());
  const [selectedParticipants, setSelectedParticipants] = useState<ExtendedActiveParticipant[]>([]);
  const [pickedDate, setPickedDate] = useState<string | null>(null);
  const { serviceStartDate, serviceEndDate, supportCategory, searchField } = useFiltersUrlParams();
  const [showFilters, setShowFilters] = useState(
    [serviceStartDate, serviceEndDate, supportCategory, searchField].some(Boolean),
  );

  const activeParticipantsCount =
    userData?.participants?.filter(participant => participant.is_active === true).length || 0;

  const hasMultipleActiveParticipants = activeParticipantsCount > 1;

  const activeParticipants =
    userData?.participants
      ?.filter(participant => participant.is_active)
      .map((p, index) => ({ ...p, role: index === 0 ? ICONS.STAR : ICONS.CIRCLE })) || [];

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleChangeView = useCallback((_: SyntheticEvent, newValue: VIEW) => {
    setView(newValue);
  }, []);

  const handleChangeMonth = useCallback(
    (selector: SELECTOR) => {
      setSelectedMonth(getMonthWithSelectorIso(selector, selectedMonth));
    },
    [selectedMonth],
  );

  const handleParticipantChange = useCallback(
    (_event: SyntheticEvent, newSelectedParticipants: ExtendedActiveParticipant[]) => {
      setSelectedParticipants(newSelectedParticipants);
    },
    [],
  );

  const handlePickDate = useCallback((date: string) => setPickedDate(date), []);

  return {
    open,
    toggleDrawer,
    setDocumentId,
    documentId,
    handleChangeView,
    view,
    selectedMonth,
    handleChangeMonth,
    isLoadingUserData,
    hasMultipleActiveParticipants,
    userData,
    handleParticipantChange,
    selectedParticipants,
    activeParticipants,
    setSelectedMonth,
    pickedDate,
    handlePickDate,
    setPickedDate,
    categoriesOptions,
    isLoadingCategoryOptions,
    showFilters,
    setShowFilters,
  };
};

export const useInvoicesContext = () => {
  const context = useContext(InvoicesContext);
  if (!context) throw new Error('useInvoicesContext must be inside InvoicesProvider');
  return context;
};

export const InvoicesProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useInvoicesContextValue();
  return <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>;
};
