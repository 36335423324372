import { LinkProps as RouterLinkProps } from 'react-router-dom';

import { Box, BoxProps, Divider, Link, LinkProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledTasksBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: alpha(theme.colors.black as string, 0.2),
  [theme.breakpoints.down('sm')]: {
    gap: '18px',
  },
}));

export const StyledTasksDivider = styled(Divider)(({ theme }) => ({
  height: 'auto',
  backgroundColor: alpha(theme.colors.white as string, 0.2),
}));

export const StyledTasksLink = styled(Link)<LinkProps & RouterLinkProps>(({ theme }) => ({
  color: theme.colors.white,
}));
