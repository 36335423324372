import { FC, memo, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import CardsList from '@pages/Tasks/components/CardsList';
import EmptyList from '@pages/Tasks/components/EmptyList';
import SortingGroup from '@pages/Tasks/components/SortingGroup';
import { useTasksContext } from '@pages/Tasks/context';
import { DocumentView, TaskView } from '@pages/Tasks/enums';
import { ApplyToAllModalId } from '@pages/Tasks/modals/ApplyToAllModal';
import { StyledAlert, StyledDivider } from '@pages/Tasks/styled';
import { getTranslationKey } from '@pages/Tasks/utils';

import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton';

interface ClaimViewProps {
  amount: number;
}

const ClaimView: FC<ClaimViewProps> = ({ amount }) => {
  const { t } = useTranslation();
  const { downMd, downLg } = useBreakpoints();
  const {
    handleSortBy,
    sortBy,
    documentView,
    unclaimedArr,
    handleSameDate,
    isLoadingMarkInvoices,
    handleMarkInvoices,
    editClaimForm,
    handleToggleDocumentView,
    view,
    handleUnselectAll,
    claimSelectedIds,
  } = useTasksContext();

  const onConfirm = async () => {
    await handleMarkInvoices();
    handleToggleDocumentView();
  };

  const onCancel = () => {
    handleToggleDocumentView();
    handleUnselectAll();
  };

  useEffect(() => {
    // toggle document view if user was on previous tab and came back via browser back button while editing
    if (documentView === DocumentView.EDITOR) {
      handleToggleDocumentView();
    }
  }, []);

  return (
    <Box>
      <Box width="100%" display="flex" alignItems="center">
        <Box maxWidth="100%">
          <Typography fontFamily="WFVisualSans" fontSize={20} mb="5px" lineHeight="32px">
            {documentView === DocumentView.DETAILS ? t('tasks.claim.title') : t('tasks.claim.editorTitle')}
          </Typography>
          <Typography fontWeight={400}>
            {documentView === DocumentView.DETAILS && (
              <Trans
                i18nKey={getTranslationKey(amount, 'claim')}
                values={{ amount }}
                components={{ bold: <strong /> }}
              />
            )}
            {documentView === DocumentView.EDITOR && t('tasks.claim.subtitle_edit')}
          </Typography>

          {/* EDIT MODE ALERT */}
          {documentView === DocumentView.EDITOR && view === TaskView.CLAIM && (
            <StyledAlert severity="info">
              <Box
                width="100%"
                display={downLg ? 'block' : 'flex'}
                justifyContent={downLg ? 'start' : 'space-between'}
                flexGrow={1}
                alignItems="center"
              >
                <Typography>{t('tasks.didYouKnowTitle')}</Typography>
              </Box>
              <Typography fontSize={14} fontWeight={400}>
                {t('tasks.didYouKnowText')}
              </Typography>
            </StyledAlert>
          )}
        </Box>

        {/* SORT BOX */}
        {amount > 0 && !downMd && documentView === DocumentView.DETAILS && (
          <Box display="flex" alignSelf="center" flexDirection="column" alignItems="flex-end" flexGrow={1}>
            <Box>
              <Typography fontSize={13} fontWeight={400} mb="10px">
                {t('tasks.sortList')}
              </Typography>
              <SortingGroup sortValue={sortBy} handleChange={handleSortBy} />
            </Box>
          </Box>
        )}
      </Box>
      {amount > 0 && <StyledDivider />}

      {!downMd && documentView === DocumentView.EDITOR && claimSelectedIds.length > 1 && (
        <Box display="flex" alignSelf="center" flexDirection="column" mb={3}>
          <Button
            variant="outlined"
            sx={{ height: 42, width: 290, boxShadow: 'none' }}
            onClick={() => {
              NiceModal.show(ApplyToAllModalId, {
                handleApplyToAll: handleSameDate,
                view,
              });
            }}
            color="primary"
          >
            <Typography>{t('tasks.claim.allClaimedSameDay')}</Typography>
          </Button>
        </Box>
      )}

      <FormProvider {...editClaimForm}>
        <Box component="form" onSubmit={editClaimForm.handleSubmit(onConfirm, err => console.log(err))}>
          {!downMd && documentView === DocumentView.DETAILS && amount > 0 && (
            <Box display="flex" gap="10px" alignItems="center" mb={5}>
              <InfoIcon color="action" />
              <Typography fontSize={14} fontWeight={400} lineHeight="27px">
                {t('tasks.claim.subtitle_2')}
              </Typography>
            </Box>
          )}
          <CardsList documentView={documentView} invoices={unclaimedArr} />
          {documentView === DocumentView.EDITOR && (
            <Box
              display="flex"
              flexGrow={downLg ? 1 : 0}
              justifyContent={downLg ? 'center' : 'flex-start'}
              flexDirection={downMd ? 'column-reverse' : 'row'}
              gap={{
                xs: 5,
                md: 4,
                lg: 2,
              }}
              mt={7}
              px={{
                xs: 0,
                sm: '60px',
                md: 0,
              }}
            >
              <Button
                variant={downMd ? 'text' : 'outlined'}
                sx={{ height: 42, boxShadow: 'none' }}
                type="button"
                onClick={onCancel}
              >
                <Typography>{t('common.cancel')}</Typography>
              </Button>
              <LoadingButton
                fullWidth={downLg}
                loading={isLoadingMarkInvoices}
                endIcon={<CheckIcon />}
                color="primary"
                variant="contained"
                sx={{ height: 42 }}
                type="submit"
              >
                <Typography>
                  {t(`tasks.claim.${claimSelectedIds.length > 1 ? 'markAllClaimed' : 'markClaimed'}`)}
                </Typography>
              </LoadingButton>
            </Box>
          )}
        </Box>
      </FormProvider>

      {/* EMPTY STATE */}
      {amount < 1 && (
        <Box mt={5}>
          <EmptyList view={view} />
        </Box>
      )}
    </Box>
  );
};

export default memo(ClaimView);
