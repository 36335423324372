import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { INVOICE_ID } from '@constants/common';
import { ROUTING } from '@constants/routing';
import ConfirmEmail from '@pages/ConfirmEmail';
import CreateCapsureId from '@pages/CreateID';
import { CreateIdProvider } from '@pages/CreateID/context';
import DeletedPage from '@pages/Deleted';
import ForgotPassword from '@pages/ForgotPassword';
import { InvoiceDetailsNewProvider } from '@pages/InvoiceDetails/context';
import Invoices from '@pages/Invoices';
import { InvoicesProvider } from '@pages/Invoices/context';
import Login from '@pages/Login';
import NotFound from '@pages/NotFound';
import Relationship from '@pages/Relationship';
import ResetPassword from '@pages/ResetPassword';
import Settings from '@pages/Settings';
import { SettingsProvider } from '@pages/Settings/context';
import Signup from '@pages/Signup';
import SubscribePage from '@pages/SubscribePage';
import Tasks from '@pages/Tasks';
import { TasksProvider } from '@pages/Tasks/context';
import ErrorBoundary from '@providers/layouts/ErrorBoundary';
import PrivatePreviewLayout from '@providers/layouts/PrivatePreviewLayout/PrivatePreviewLayout';
import ConfirmEmailGuard from '@routes/guards/ConfirmEmailGuard';
import ParticipantGuard from '@routes/guards/ParticipantGuard';
import Home from 'src/pages/Home';
import InvoiceDetails from 'src/pages/InvoiceDetails';

import AuthorizationLayout from '@components/AuthorizationLayout';
import Loading from '@components/Loading/Loading';
import RegistrationLayout from '@components/RegistrationView';

import PublicRegistrationGuard from './guards/PublicRegistrationGuard';
import PrivateRouteOutlet from './PrivateRouteOutlet';
import PrivateRoutes from './PrivateRoutes';

const PUBLIC_ROUTES = [
  { path: ROUTING.LOGIN, Component: Login, Wrapper: AuthorizationLayout },
  { path: ROUTING.SIGNUP, Component: Signup, Wrapper: RegistrationLayout },
  { path: ROUTING.FORGOT_PASSWORD, Component: ForgotPassword, Wrapper: AuthorizationLayout },
  { path: ROUTING.RESET_PASSWORD, Component: ResetPassword, Wrapper: AuthorizationLayout },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <PrivateRoutes />
      </ErrorBoundary>
    ),
    children: [
      {
        element: (
          <PrivatePreviewLayout>
            <PrivateRouteOutlet />
          </PrivatePreviewLayout>
        ),
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: ROUTING.INVOICES,
            element: (
              <InvoicesProvider>
                <Invoices />
              </InvoicesProvider>
            ),
          },
          {
            path: `${ROUTING.INVOICES}/:${INVOICE_ID}`,
            element: (
              <InvoiceDetailsNewProvider>
                <InvoiceDetails />
              </InvoiceDetailsNewProvider>
            ),
          },
          {
            path: ROUTING.SETTINGS,
            element: (
              <SettingsProvider>
                <Settings />
              </SettingsProvider>
            ),
          },
          {
            path: ROUTING.TASKS,
            element: (
              <TasksProvider>
                <Tasks />
              </TasksProvider>
            ),
          },
        ],
      },
      {
        path: ROUTING.CONFIRM_EMAIL,
        element: (
          <Suspense fallback={<Loading />}>
            <ConfirmEmailGuard>
              <RegistrationLayout>
                <ConfirmEmail />
              </RegistrationLayout>
            </ConfirmEmailGuard>
          </Suspense>
        ),
      },
      {
        path: ROUTING.CREATE_ID,
        element: (
          <Suspense fallback={<Loading />}>
            <RegistrationLayout>
              <CreateIdProvider>
                <CreateCapsureId />
              </CreateIdProvider>
            </RegistrationLayout>
          </Suspense>
        ),
      },
      {
        path: ROUTING.SUBSCRIBE,
        element: (
          <Suspense fallback={<Loading />}>
            <SubscribePage />
          </Suspense>
        ),
      },
      {
        path: ROUTING.NDIS,
        element: (
          <Suspense fallback={<Loading />}>
            <ParticipantGuard>
              <RegistrationLayout>
                <Relationship />
              </RegistrationLayout>
            </ParticipantGuard>
          </Suspense>
        ),
      },
    ],
  },
  ...PUBLIC_ROUTES.map(({ path, Component, Wrapper }) => ({
    path,
    element: (
      <Suspense fallback={<Loading />}>
        <PublicRegistrationGuard>
          <Wrapper>
            <Component key={path} />
          </Wrapper>
        </PublicRegistrationGuard>
      </Suspense>
    ),
  })),
  {
    path: ROUTING.ACCOUNT_DELETED,
    element: <DeletedPage />,
  },
  {
    path: '*',
    element: (
      <Suspense>
        <NotFound />
      </Suspense>
    ),
  },
]);

const Routing = () => <RouterProvider router={router} />;

export default Routing;
